
import { useQuery,useQueryClient,useMutation } from 'react-query'
import {useSnackbar} from 'notistack5';
import axios from '../../utils/axios'
import Axiosdownload from '../../utils/axiosForDownload'

export  function useContactsForOneUser(id,load=true){
    console.log(id)
    return  useQuery(`/users/getContacts/${id}`,
    async () => {
      const { data } = await axios.get(`/users/getContacts/${id}`)
      return data?.data;
    },
    {
      enabled: Boolean(load),
    }
    );
}
export  function usePreviewContacts(id, setTrackUpload){
  const createEmployee = async (newPost) => {
    const { data } =await axios.post(`/users/TreatedContactToDisplay/${id}`, newPost,{
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setTrackUpload(percentCompleted);
      }})
  
    return data;
  };
  return useMutation((newPost)=>createEmployee(newPost), {
    onSuccess: data => {
      console.log(data);
      const message = "success"
      return data;
    },
    onError: (e) => {
      console.log(e);
    },
  });
}
export  function useImporterContacts(id, setTrackUpload){
  const queryClient=useQueryClient();
  const createEmployee = async (newPost) => {
    const { data } =await axios.post(`/users/saveContactsCsv/${id}`, newPost,{
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setTrackUpload(percentCompleted);
      }})
  
    return data;
  };
  return useMutation((newPost)=>createEmployee(newPost), {
    onSuccess: data => {
      queryClient.invalidateQueries(`/users/getContacts/${id}`)
      console.log(data);
      const message = "success"
      return data;
    },
    onError: (e) => {
      console.log(e);
    },
  });
}

export  function useGetContactsLenght(load=true, id, admin){
    return useMutation(`/users/getContacts/${id}/${admin}`,
    async () => {
      const { data } = await axios.get(`/users/getContacts/${id}/${admin}`)
      return data?.data;
    },
    {
      enabled: Boolean(load),
    }
    );
}

export  function useSaveContacts(idOfCurrentUser){
  const queryClient=useQueryClient();
  return useMutation(`/users/saveContact`,
  async (contacts) => {
    const { data } = await axios.post(`/users/saveContact`, {"contactArray":contacts})
    return data?.data;
  },
  {
    onSuccess: function(){
      queryClient.invalidateQueries(`/users/getContacts/${idOfCurrentUser}`)
    }
  }
  );
}
export  function useAddcontacts(idOfCurrentUser){
  console.log(idOfCurrentUser);
    const queryClient=useQueryClient();
    return useMutation(
      (values) => axios.post(`/users/createContact/${idOfCurrentUser}`, values).then((res) => res.data),
    {
      onSuccess: function(newPost){
        queryClient.invalidateQueries(`/users/getContacts/${idOfCurrentUser}`)
      }
    }
    );
}

export function useUpdateContacts(id,idOfCurrentUser){
  const queryClient=useQueryClient();
  return useMutation((newPost) => axios
  .put(`/users/modifyContact/${id}`, newPost)
  .then((res) => res.data),
  {
    onSuccess: function(newPost){ 
        queryClient.invalidateQueries(`/users/getContacts/${idOfCurrentUser}`)
        return newPost
    }
    
  }
  )
}

export function useDeleteContacts(id,idOfCurrentUser){
    console.log(id,idOfCurrentUser);
    const { enqueueSnackbar } = useSnackbar();
    const queryClient=useQueryClient();
    return useMutation((newPost) => axios
    .delete(`/users/deleteContact/${id}`)
    .then((res) => res.data),
    {
      onMutate: editevalues=>{
          if(!queryClient.getQueryData(`/users/getContacts/${idOfCurrentUser}`)) return  queryClient.invalidateQueries(`/users/getContacts/${idOfCurrentUser}`);
          const previousValues= queryClient.getQueryData(`/users/getContacts/${idOfCurrentUser}`)
          console.log(previousValues)
          const updatedValues= [...previousValues]
          const removeDeleted= updatedValues.filter((eachValues)=>eachValues._id!==id)
          queryClient.setQueryData(`/users/getContacts/${idOfCurrentUser}`, removeDeleted)
          enqueueSnackbar('contacts suprimer', { variant: 'success' });
        },
        onError:(error, editedValue, rollback)=> rollback(),
        onSettled:(data, error, editedValue)=>{
          console.log(editedValue)
          console.log(data)
          queryClient.removeQueries([`/users/getContacts/${idOfCurrentUser}`, id])
          queryClient.invalidateQueries(`/users/getContacts/${idOfCurrentUser}`)
          
      }
      
    }
    )
}
export function useDeleteManyContacts(idOfCurrentUser){
    const queryClient=useQueryClient();
    return useMutation(async(newPost) => {
      console.log(newPost);
      return axios
    .delete(`/users/deleteContactMany/${idOfCurrentUser}`, { data: newPost })
    .then((res) => res.data)
    },
    {
        onMutate: editevalues=>{
         console.log(editevalues)
        },
        onSuccess: editevalues=>{
          if(!queryClient.getQueryData(`/users/getContacts/${idOfCurrentUser}`)) return  queryClient.invalidateQueries(`/users/getContacts/${idOfCurrentUser}`);
          queryClient.invalidateQueries(`/users/getContacts/${idOfCurrentUser}`)
        },
        onError:(error, editedValue, rollback)=> rollback(),
        onSettled:(data, error, editedValue)=>{
          console.log(editedValue)
          console.log(data)
          queryClient.invalidateQueries(`/users/getContacts/${idOfCurrentUser}`)
      }
      
    }
    )
}

export  function useDownloadContatnsFileTemplate(id){
  return  useMutation(`/public/uploads/template_excel.xlsx`,
  async (contacts) => {
    const { data } = await Axiosdownload.get(`/public/uploads/template_excel.xlsx`,{
      responseType: 'blob',
   })
    return data;
  }
  );
}