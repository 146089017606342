import * as Yup from 'yup';
import { useState } from 'react';
import {useSnackbar} from 'notistack5';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  Alert,
  TextField, Box, Button} from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@material-ui/lab';

import useAuth from '../../../hooks/userAuth';
import useCrediter from '../../../hooks/Api/useCrediter';

const Crediter =({setisLoading,isLoading, handleClose, userData})=>{
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const  auth= useAuth();
    // const [Crediter, CrediterResut ]= useCrediter();
    const creditMutation = useCrediter();
    const validationSchema = Yup.object().shape({
      sms_credit:Yup.string().required("entrez le nombre d'sms à crediter"),
    });
    
    const { handleSubmit, reset, control, formState: { errors }} = useForm({
      resolver: yupResolver(validationSchema)
});
    const onSubmitdata =(data) =>{
      console.log(userData)
      console.log(data)
      if(Number(data.sms_credit)<0){
        setError('cette valeur ne peut être négative')
      }else if(!Number.isInteger(Number(data.sms_credit))){
        setError("entrer un nombre valide")
      }else{
        CreditSms(data) 
      } 
    };
    
    const CreditSms= async(data)=>{
        setisLoading(true);
        console.log(data);
         creditMutation.mutate(data);
        setTimeout(() => {
          setisLoading(false);
          handleClose()
        }, 1000);
        
      }
    console.log(userData);
    return (
    <form>
      <Controller
        name="sms_credit"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField autoFocus fullWidth error={Boolean(errors.crediter)}  helperText={errors.crediter}  margin="dense" onChange={onChange} value={value || ''} label="Crediter" />
        )}
      />
      <Controller
        name="userId"
        control={control}
        defaultValue={userData._id}
        render={() => (
          <TextField  type="hidden" value={userData._id} />
        )}
      />
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
      <LoadingButton fullWidth size="md" onClick={handleSubmit(onSubmitdata)}   variant="contained" loading={isLoading}>
          Crediter
      </LoadingButton>
      <LoadingButton sx={{ml:2}} fullWidth size="md" color="warning" onClick={()=>handleClose()}   variant="contained">
          fermer
      </LoadingButton>
      </Box>
    </form>
    );
}
export default Crediter