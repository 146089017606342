import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      
      <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%" height="100%"
      viewBox="0 0 3941.736 5070.136"
    >
      <defs>
        <linearGradient
          id="a"
          x1="0.571"
          x2="0.941"
          y1="0.789"
          y2="0.325"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ff0080"/>
          <stop offset="1" stopColor="#7928ca"/>
        </linearGradient>
        <linearGradient
          id="b"
          x1="0.574"
          x2="0.144"
          y1="0"
          y2="0.08"
          xlinkHref="#a"
        />
        <linearGradient
          id="c"
          x1="0.467"
          x2="0.741"
          y1="0.358"
          y2="0.416"
          xlinkHref="#a"
        />
        <linearGradient
          id="d"
          x1="0.874"
          x2="0.124"
          y1="0.621"
          y2="0.364"
          xlinkHref="#a"
        />
      </defs>
      <path
        fill="url(#a)"
        d="M-995.747 773.256s-102.324 752.066 368.71 1410.857 1515.428 1224.3 1515.428 1224.3 460.621-203.816 836.13-495.332 634.206-570.188 691.178-759.1-100.021-437.553-284.551-567.078-647.625-292.763-647.625-292.763 216.739 154.485 58.076 440.948-703.17 718.475-703.17 718.475-768.675-312.544-1234.677-718.474-599.499-961.833-599.499-961.833z"
        transform="translate(-691.432 -711.938) translate(1706.078 2373.661)"
      />
      <path
        fill="url(#b)"
        d="M2907.531 2963.151l-27.286-1493S2079.324 731.889 952.791 666.012-1004.2 1355.131-1004.2 1355.131 122.335 924.842 621.953 1000.567s916.97 384.894 1327.447 759.9 958.131 1202.684 958.131 1202.684z"
        transform="translate(-691.432 -711.938) translate(1725.637 49.999)"
      />
      <path
        fill="url(#c)"
        d="M2500.583 2100.082s-701.552-748.5-1265.287-796.476-943.871 108.534-1119.175 387.632 219.417 463.072 219.417 463.072-505-92.979-847.567-368.024-492.15-720.5-492.15-720.5S-20.433 483.388 855.758 741.962s1644.825 1358.12 1644.825 1358.12z"
        transform="translate(-691.432 -711.938) translate(1726.002 338.666)"
      />
      <path
        fill="url(#d)"
        d="M2363.71 3941.147s356.676-401.135 444.09-793.874 119.945-551.742-62.969-794.629-573.943-360.754-1060.687-414.544-1221.279-136.838-1221.279-136.838-543.412-89.105-905.746-362.757S-975.016 693.6-975.016 693.6s-29.895 376.463-29.895 700.31v570.3s-30.455 97.547 322.492 470.5S98 2814.788 98 2814.788s671.2-.263 1202.027 122.629 800.645 249.5 998.554 468.393 65.129 535.337 65.129 535.337z"
        transform="translate(-691.432 -711.938) translate(1697.001 710.982)"
      />
    </svg>
    </Box>
  );
}
