
import { useQuery,useQueryClient,useMutation } from 'react-query'
import axios from '../../utils/axios'
import useAuth from '../userAuth';

export  function useTarif(load=true){
    return useQuery(`/sms/getTarif`,
    async () => {
      const { data } = await axios.get(`/sms/getTarif`)
      return data?.data;
    },
    {
      enabled: Boolean(load),
    }
    );
}

export function useAddTarif(){
  const queryClient=useQueryClient();
  return useMutation((newPost) => axios
  .post(`/sms/addTarif`, newPost)
  .then((res) => res.data),
  {

    onSuccess: function(newPost){ 
       queryClient.setQueriesData(`/sms/getTarif`)
    }
    
  }
  )
}
export function useUpdateTarif(){
  const queryClient=useQueryClient();
  return useMutation((newPost) => axios
  .put(`/sms/updateTarif/${newPost.id}`, newPost.data)
  .then((res) => res.data),
  {

    onSuccess: function(newPost){ 
       queryClient.setQueriesData(`/sms/getTarif/`)
    }
    
  }
  )
}
export default {useTarif, useUpdateTarif }
