import { useState } from 'react';
import { HotTable } from '@handsontable/react';
import {useSnackbar} from 'notistack5';
import 'handsontable/dist/handsontable.full.css';
import {Button, Container, Icon, Stack} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import  {useSendSmsToContactsFile} from "../../../hooks/Api/useClasses"

const PreviewCLasseData=({idOfCUrrentUser, setisLoading, classe, isLoading, handleClose,})=>{
    console.log(classe);
    const [values, setvalues] = useState(classe);
    const saveContacts=useSendSmsToContactsFile(idOfCUrrentUser);
    const { enqueueSnackbar} = useSnackbar();

    const handleHOTChange=(changes, source)=>{
        console.log(changes);
        setvalues(values)
      }
    const uploadfile=async()=>{
        if(values!==null && values!==[]){
           sendData(values);
        }else{
          enqueueSnackbar('le document est vide, éditer un fichier et reimporter', { variant: 'success' });
        }
    }
    const sendData=(data)=>{
        saveContacts.mutate({'contactArray': data},{
            onSuccess: (data) =>{
                enqueueSnackbar('importation reussit', { variant: 'success' });
                handleClose()
            },
            onError: (e) => {
              console.log(e);
              enqueueSnackbar('oops une erreur est survenu ', { variant: 'error' });
            },
          })
    }
    return(
        <Container   >
         <Stack  sx={{  marginTop:5, width:'100vh', display: 'flex' }} spacing={2} direction="column" justifyContent="space-between">
             <HotTable
            data={values}
            id="hot"
            width="100hv"
            height='auto'
            stretchH='all'
            contextMenu={true}
            colHeaders={['matriculeElv', 'Appelations', 'nomElv', 'numeroParent', 'niveauElv', 'classe', 'objects', 'notes', 'appreciations', 'rangs', 'abscences' ]}
            rowHeaders= {true}
            hiddenColumns={{
                columns:[11]
              }}
            licenseKey="non-commercial-and-evaluation"
            onAfterChange={(changes, source) => handleHOTChange(changes, source)}
            />
            <Stack spacing={2} direction="row">
             <LoadingButton fullWidth size="large" startIcon={<Icon icon="iconoir:antenna-signal"/>} type="submit" variant="contained" loading={saveContacts.isLoading}   onClick={()=>{uploadfile()}} >
              Diffusser Les notes aux parents
            </LoadingButton>

             <LoadingButton fullWidth color="error"  size="large" type="submit" variant="contained"    onClick={handleClose} >
              fermer
            </LoadingButton>

            </Stack>

         </Stack>
        </Container>
    )
}
export default PreviewCLasseData