import {useEffect, useState} from "react";
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import {useSnackbar} from 'notistack5';
// material
import { Box, Stack, Avatar, Tooltip, Typography, CircularProgress, Button } from '@material-ui/core';
// utils

//
import { MIconButton } from '../../@material-extend';
import { useGetGroupes, useAddToexistingGroupeGroupesForOneUser } from '../../../hooks/Api/useGroupes';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AddToGroupe({idOfCurrentUser, contactsArray, handleClose}) {
  const { enqueueSnackbar } = useSnackbar();
  const [getGroupeId, setgetGroupeId] = useState()
  const {data, refetch}=useGetGroupes(idOfCurrentUser);
  const addToGroupe=useAddToexistingGroupeGroupesForOneUser(getGroupeId, idOfCurrentUser);
  console.log(contactsArray)
  function ExtractIdOFContacts(existingcontacts){
    const arrayids = [];
    console.log(existingcontacts)
    existingcontacts.map(d => arrayids.push(d._id));
  return arrayids;
  }
  const AddToexistingGroupe=(existingcontacts, groupeName)=>{
    const result= ExtractIdOFContacts(existingcontacts).concat(contactsArray); 
    const cleanArry= result.filter((item, index) => result.indexOf(item) === index)
    const detailsGroupeToAdd={'groupName': groupeName,'listContact': cleanArry};
    addToGroupe.mutate(detailsGroupeToAdd,{
      onSuccess:()=>{
        enqueueSnackbar('contacts ajouter', { variant: 'success' });
        setgetGroupeId()
        refetch().then(()=>{handleClose()})
      }
    })

  }
  useEffect(() => {
    
    return () => {
      return 0
    }
  }, [getGroupeId])
  return(
    <>
      <Stack spacing={3} sx={{ p: 3, width:"100%" }}>
        {data?.map((item) => (
          <Stack direction="row" alignItems="center" key={item._id}>
            <Avatar src={item.name} sx={{ width: 48, height: 48 }} />
            <Box sx={{ flexGrow: 1, ml: 2, minWidth: 100 }}>
              <Typography variant="subtitle2" sx={{ mb: 0.5 }} noWrap>
                {item.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {item.contacts.length} contact(s)
              </Typography>
            </Box>

            
            {
              addToGroupe.isLoading&& item._id===getGroupeId? <CircularProgress color="inherit" /> :
              <Tooltip title="Ajouter">
                <MIconButton size="small"  color="inherit" variant="contained"onClick={()=>{
                setgetGroupeId(item._id)
                if(getGroupeId){
                  AddToexistingGroupe(item.contacts,item.name)
                }
                }}>
                <Icon icon={plusFill} width={22} height={22} />
              </MIconButton>
             </Tooltip>
            }
              
           
          </Stack>
        ))}
        {data.length===0?<center><Stack spacing={3} sx={{ alignContent:"center"}}>  <strong> ☹ aucun créer groupe pour l'instant</strong></Stack></center>: null}
      </Stack>
      <Button
        fullWidth
        type="button"
        color="warning"
        variant="contained"
        size="large"
        onClick={handleClose}
        sx={{ mr: 1.5 }}
        >
          Ferner
        </Button>
       
   </>
  );
}
