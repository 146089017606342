import * as Yup from 'yup';
import {useSnackbar} from 'notistack5';
import {useEffect, useState} from "react";
// material
import {
  TextField, Box} from '@material-ui/core';
import { useLocation,useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@material-ui/lab';
import { useGetGroupes, useAddToexistingGroupeGroupesForOneUser } from '../../../hooks/Api/useGroupes';

import useAuth from '../../../hooks/userAuth';
import {useAddcontacts} from '../../../hooks/Api/useContacts';
import { regex, iff} from '../../../utils/utils';

const AddContacts =({setisLoading,isLoading, handleClose, idOfCUrrentUser})=>{
  const { pathname } = useLocation();
  const user= useAuth();
  const { name, idGroupes } = useParams();
  console.log(name);
  console.log(idGroupes);
  const groupes= useGetGroupes(iff(idGroupes===undefined, user.user.id, idGroupes));
  const addToGroupe=useAddToexistingGroupeGroupesForOneUser(name, iff(idGroupes===null, user.id, idGroupes));
  const isdetailGroupes = pathname.includes('listes');
    const { enqueueSnackbar } = useSnackbar();
    const AddContacts= useAddcontacts(idOfCUrrentUser);
    const validationSchema = Yup.object().shape({
      phone:Yup.string().matches(regex, 'Ce numero de télephone est invalide')
      .required('entrez votre numéro de télephone '),
       });
    
    const { handleSubmit, reset, control, } = useForm({
      resolver: yupResolver(validationSchema)
    });
    const onSubmitdata =(data) =>{
      SaveContacts(data) 
    }; 

    function ExtractIdOFContacts(existingcontacts){
      const arrayids = [];
      console.log(existingcontacts)
      existingcontacts.map(d => arrayids.push(d._id));
    return arrayids;
    }
   
    const SaveContacts= async(data)=>{
        setisLoading(true);
        console.log(data);
        AddContacts.mutate(data,{
          onSuccess:(data)=>{
            reset();
            if(isdetailGroupes){
              const currentGroupe=groupes.data.filter((c)=> c._id===name)[0];
              const result=ExtractIdOFContacts(currentGroupe.contacts).concat(data.data._id);
              console.log(data);
              console.log(result);
              const detailsGroupeToAdd={'groupName': currentGroupe.name,'listContact': result};
              addToGroupe.mutate(detailsGroupeToAdd,{
                onSuccess:()=>{
                  enqueueSnackbar('contacts ajouter', { variant: 'success' });
                  groupes.refetch()
                }
              })
             }
          }
        });
        setTimeout(() => {
          setisLoading(false);
          handleClose()
        }, 1000);
        
      }    
    return (
    <form>
      <Controller
    name="name"
    control={control}
     render={({ field, fieldState: { error } }) => (
              <TextField {...field}autoFocus fullWidth label="Nom" margin="dense" error={Boolean(error)} helperText={error?.message} />
    )}
    />
    <Controller
    name="phone"
    control={control}
     render={({ field, fieldState: { error } }) => (
              <TextField {...field}autoFocus fullWidth label="Phone"  margin="dense"  error={Boolean(error)} helperText={error?.message} />
    )}
    />
    <Controller
    name="ville"
    control={control}
     render={({ field, fieldState: { error } }) => (
              <TextField {...field}autoFocus fullWidth label="Ville"  margin="dense"  error={Boolean(error)} helperText={error?.message} />
    )}
    />
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
      <LoadingButton fullWidth size="md" onClick={handleSubmit(onSubmitdata)}   variant="contained" loading={isLoading}>
          Ajouter
        </LoadingButton>
      </Box>
    </form>
    );
}
export default AddContacts