import { useQuery } from 'react-query';
import axios from '../../utils/axios'


export const post=(userInfos, url)=>{
   axios.post(url, userInfos).then((res)=> res.data);
} 
export const get= async(url)=>{
    const {data} = await axios.get(url);
    return data.data;
}
export const put=(userInfos, url)=>{
    axios.put(url, userInfos).then((res)=> res.data);
}
export const deleteFunc=(userInfos, url)=>{
    axios.delete(url, userInfos).then((res)=> res.data);
}

export default {post, put, get, deleteFunc}