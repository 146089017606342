import { Suspense } from "react";
import { useLocation } from "react-router-dom";
import { hot } from "react-hot-loader";
// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
// components
import Settings from "./components/settings";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import NotistackProvider from "./components/NotistackProvider";
import LoadingScreen from "./components/LoadingScreen";
// ----------------------------------------------------------------------

const App = () => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <ThemeConfig>
        <ThemePrimaryColor>
          <RtlLayout>
            <NotistackProvider>
              <Settings />
              <ScrollToTop />
              <Router />
            </NotistackProvider>
          </RtlLayout>
        </ThemePrimaryColor>
      </ThemeConfig>
    </Suspense>
  );
};

export default hot(module)(App);
