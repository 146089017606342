import * as Yup from "yup";
import { useSnackbar } from "notistack5";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
// material
import { LoadingButton } from "@material-ui/lab";
import { Box, Grid, Stack, TextField } from "@material-ui/core";
// routes
//
import countries from "../../_dashboard/user/form/countries";
import { useCreateSubAccountUser } from "../../../hooks/Api/useCreateUser";
import { regex } from "../../../utils/utils";

// ----------------------------------------------------------------------

export default function SubAccount({ isEdit, currentUser, handleClose, id }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const EditUser = useCreateSubAccountUser();
  const NewUserSchema = Yup.object().shape({
    first_name: Yup.string().required("Name est requis"),
    last_name: Yup.string().required("Prenom est requis"),
    email: Yup.string().required("Email est requis").email(),
    phone: Yup.string()
      .matches(regex, "Ce numero de télephone est invalide")
      .required("entrez votre numéro de télephone "),
    country: Yup.string().required("seletionnez un pays"),
    password: Yup.string().required("Entrer un mot de passe "),
    confirmPassword: Yup.string()
      .required("Veuillez confirmez le mot de passe ")
      .oneOf(
        [Yup.ref("password"), null],
        "Vos mot de passe ne correspondent pas"
      ),
    city: Yup.string().required("City is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: currentUser?.first_name || "",
      email: currentUser?.email || "",
      phone: currentUser?.phone || "",
      sender_sms_name: currentUser?.sender_sms_name || "",
      country: currentUser?.country || "",
      city: currentUser?.city || "",
      password: currentUser?.type_de_compte || "",
      last_name: currentUser?.last_name,
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      console.log(values);

      try {
        setSubmitting(true);

        EditUser.mutate(values, {
          onSuccess: function (data) {
            resetForm();
            enqueueSnackbar(
              !isEdit ? "Le compte a bien été créer" : "compte mise a jour ",
              { variant: "success" }
            );
            setTimeout(() => {
              handleClose();
            }, 1000);
          },
        });
        setSubmitting(false);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ paddingTop: 3 }}>
          <Grid item xs={12}>
            <Stack spacing={3}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <TextField
                  fullWidth
                  label="Nom"
                  {...getFieldProps("first_name")}
                  error={Boolean(touched.first_name && errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                />
                <TextField
                  fullWidth
                  label="Prénom"
                  {...getFieldProps("last_name")}
                  error={Boolean(touched.last_name && errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                />
              </Stack>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <TextField
                  fullWidth
                  label="Numéro de Téléphone"
                  {...getFieldProps("phone")}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
                <TextField
                  select
                  fullWidth
                  label="Country"
                  placeholder="Country"
                  {...getFieldProps("country")}
                  SelectProps={{ native: true }}
                  error={Boolean(touched.country && errors.country)}
                  helperText={touched.country && errors.country}
                >
                  <option value="" />
                  {countries.map((option) => (
                    <option key={option.code} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Stack>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <TextField
                  fullWidth
                  label="City"
                  {...getFieldProps("city")}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                />
                <TextField
                  fullWidth
                  label="Mot de passe"
                  placeholder="entrez le mot de passe"
                  type="password"
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Stack>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <TextField
                  fullWidth
                  label="Mot de passe"
                  placeholder="confirmez le mot de passe  "
                  type="password"
                  {...getFieldProps("confirmPassword")}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Stack>
              <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={EditUser.isLoading}
                >
                  {!isEdit ? "Create User" : "Enregister les changements"}
                </LoadingButton>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
