import * as Yup from "yup";
import { useSnackbar } from "notistack5";
import { useEffect, useState } from "react";
// material
import { TextField, Box, Typography, Link } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@material-ui/lab";
import {
  useGetGroupes,
  useAddToexistingGroupeGroupesForOneUser,
} from "../../../hooks/Api/useGroupes";

import useAuth from "../../../hooks/userAuth";
import {
  useCheck,
  useAskForRecharge,
  usePaid,
} from "../../../hooks/Api/useSms";
import { regex, iff, RandomUid } from "../../../utils/utils";

const DemanderUneRecharge = ({
  setisLoading,
  isLoading,
  handleClose,
  idOfCUrrentUser,
}) => {
  const { pathname } = useLocation();
  const user = useAuth();
  const { name, idGroupes } = useParams();
  console.log(name);
  console.log(idGroupes);
  const auth = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const demanderLeRecharger = useAskForRecharge(auth.id);
  const check = useCheck();
  const payer = usePaid(auth.id);
  const [price, setprice] = useState(0);
  const [trans, settrans] = useState();
  const [displayOMConsigne, setdisplayOMConsigne] = useState(false);
  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(regex, "Ce numero de télephone est invalide")
      .required("entrez votre numéro de télephone "),
    countSms: Yup.string().required("entrez la quantité de sms a recharger "),
    paimentMethode: Yup.string().required(
      "selectionnez votre methode de paiements "
    ),
  });

  const { handleSubmit, reset, control, watch, getValues } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmitdata = (data) => {
    if (data.countSms >= 30) {
      AskForRecharge({
        ...data,
        amount: price,
      });
    } else {
      enqueueSnackbar(
        "Veuillez renseigner au moins 30 SMS pour pouvoir effectuer une recharge svp ",
        { variant: "error", autoHideDuration: 3000 }
      );
    }
  };
  const payerLarecharge = (data) => {
    handleClose();
  };

  const onChangeFirst = () => {
    const smsCount = watch("countSms");
    if (smsCount !== undefined || smsCount !== "") {
      const money = smsCount * getCurrentSmsCost(smsCount);
      const prix = Number.isNaN(money) ? 0 : money;
      setprice(prix);
    }
  };
  const getCurrentSmsCost = (smsCount) => {
    let currentCost = 0;
    if (smsCount > 0 && smsCount <= 1000) {
      currentCost = 16;
    } else if (smsCount > 1001 && smsCount <= 5000) {
      currentCost = 13;
    } else if (smsCount > 5001 && smsCount <= 15000) {
      currentCost = 11;
    } else if (smsCount > 15001 && smsCount <= 50000) {
      currentCost = 9;
    }
    return currentCost;
  };
  const AskForRecharge = async (data) => {
    setisLoading(true);
    console.log(data);
    demanderLeRecharger.mutate(data, {
      onSuccess: (data) => {
        console.log(data);
        settrans(data.data.transactionReference);
        setisLoading(false);
        enqueueSnackbar("demande de recharge en cours ", {
          variant: "success",
          autoHideDuration: 6000,
        });
        setdisplayOMConsigne(true);
      },
      onError: (data) => {
        console.log(data);
        reset();
        setisLoading(false);
        // setdisplayOMConsigne(true)
      },
    });
    // setTimeout(() => {
    //   setisLoading(false);
    //   handleClose()
    // }, 1000);
  };
  const Paids = async (data) => {
    setisLoading(true);
    console.log(data);
    payer.mutate(
      { ...data, transaction_ref: trans, privateKey: getValues("privateKey") },
      {
        onSuccess: (data) => {
          // reset();
          enqueueSnackbar("Recharge effectuer avec succes ", {
            variant: "success",
            autoHideDuration: 6000,
          });
          // handleClose()
        },
      }
    );
    // setTimeout(() => {
    //   setisLoading(false);
    //   handleClose()
    // }, 1000);
  };

  useEffect(() => {
    onChangeFirst();
    return () => {};
  });

  return (
    <form>
      {price !== undefined && (
        <center>
          <Typography
            variant="body2"
            sx={{ fontSize: 25, fontWeight: "bold", my: 5 }}
          >
            {Number.isNaN(price) ? 0 : price}FCFA
          </Typography>
        </center>
      )}
      {displayOMConsigne ? (
        <>
          <center>
            <Typography
              variant="body2"
              sx={{ fontSize: 25, fontWeight: "bold", my: 5 }}
            >
              Une fenêtre de validation apparaîtra dans votre mobile vous
              demandant de valider la transaction en attente <br />
              une fois valider, cliquez le button "terminer la transaction"{" "}
              <br />
              dans le cas ou la fenêtre de validation s'ouvre pas , composez le
              #150# pour OM ou bien *126# pour MOMO pour une validation manuelle
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: 20, fontWeight: "bold", color: "red", my: 5 }}
            >
              NB: une fois le virement effectué, veuillez cliquer sur le button
              "verifier" de la recharge correspodante
              <br />
            </Typography>
            <LoadingButton
              fullWidth
              size="md"
              sx={{ my: 2 }}
              onClick={handleSubmit(payerLarecharge)}
              variant="contained"
              loading={isLoading}
            >
              terminer la transaction
            </LoadingButton>
          </center>
        </>
      ) : (
        <>
          <Controller
            name="user"
            control={control}
            defaultValue={auth.id}
            render={() => <TextField type="hidden" value={auth.id} />}
          />
          <Controller
            name="amount"
            control={control}
            defaultValue={price}
            render={() => <TextField type="hidden" value={price} />}
          />

          <Controller
            name="appTransRef"
            control={control}
            defaultValue={RandomUid()}
            render={() => <TextField type="hidden" value={RandomUid()} />}
          />
          <Controller
            name="country"
            control={control}
            defaultValue={auth.user.country}
            render={() => <TextField type="hidden" value={auth.user.country} />}
          />
          <Controller
            name="name"
            control={control}
            defaultValue={auth.user.first_name}
            render={() => (
              <TextField type="hidden" value={auth.user.first_name} />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue={auth.user.email}
            render={() => <TextField type="hidden" value={auth.user.email} />}
          />
          <Controller
            name="reason"
            control={control}
            defaultValue="depôt argent "
            render={() => <TextField type="hidden" value="depôt argent" />}
          />
          <Controller
            name="privateKey"
            control={control}
            defaultValue="bf24cbac-c9de-450b-b2af-8dadafdb880f"
            render={() => (
              <TextField
                type="hidden"
                value="bf24cbac-c9de-450b-b2af-8dadafdb880f"
              />
            )}
          />
          <Controller
            name="currency"
            control={control}
            defaultValue="XAF"
            render={() => <TextField type="hidden" value="XAF" />}
          />
          <Controller
            name="phoneNumberPrefix"
            control={control}
            defaultValue="237"
            render={() => <TextField type="hidden" value="237" />}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                fullWidth
                label="votre numéro de téléphone"
                margin="dense"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="countSms"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                fullWidth
                label="nombre de sms "
                margin="dense"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="paimentMethode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                select
                fullWidth
                label="type de recharge"
                {...field}
                SelectProps={{ native: true }}
                margin="dense"
                error={Boolean(error)}
                helperText={error?.message}
              >
                <option key={0} value=" ">
                  {" "}
                </option>
                <option key={1} value="orange Money">
                  Orange Money{" "}
                </option>
                <option key={2} value="Mtn Money">
                  Mtn Mobile Money{" "}
                </option>
              </TextField>
            )}
          />
          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              fullWidth
              size="md"
              onClick={handleSubmit(onSubmitdata)}
              variant="contained"
              loading={isLoading}
            >
              Demander la recharge
            </LoadingButton>
          </Box>
        </>
      )}
    </form>
  );
};
export default DemanderUneRecharge;
