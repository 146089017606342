
import { useQuery, useMutation, queryCache, useQueryClient} from 'react-query'
import axios from '../../utils/axios';
import { get } from './userQuery';
import useAuth from '../userAuth';
import { iff } from '../../utils/utils';

export default function useCrediter(){
    const queryClient=useQueryClient();
    const auth = useAuth();
    return useMutation((newPost) => axios
    .put(`/users/creditSms/${auth.id}`, newPost)
    .then((res) => res.data),
    {

      onSuccess: function(newPost){ 
         queryClient.invalidateQueries(`/users/getAllUserRegistered/${auth.id}`)
      }
      
    }
    )
}
