import { useState, Suspense, useEffect } from 'react';
import { Outlet, useNavigate} from 'react-router-dom';
import { useIsFetching, onlineManager} from 'react-query'
import { useSnackbar } from 'notistack5';
// material
import { Button, CircularProgress, Fab, LinearProgress } from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useAuth from '../../hooks/userAuth';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { iff } from '../../utils/utils';
import { LANDING_PAGE } from '../../routes/paths';
import { useGetUser } from '../../hooks/Api/useCreateUser';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------
export  function GlobalLoading(){
  const isFetching = useIsFetching();
  return isFetching?<LinearProgress />  : null
}
export default function DashboardLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const navigate = useNavigate();
  const auth= useAuth();
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"))
  const [ConnectionState, setConnectionState] = useState()
  const [LoadState, setLoadState] = useState(false)
  const { enqueueSnackbar, closeSnackbar  } = useSnackbar();
  const isFetching = useIsFetching()
  const {data, refetch} = useGetUser( auth?.id, LoadState,setLoadState);
  const [snckbarId, setsnckbarId] = useState(null)
  useEffect(() => {
    if(!auth.getToken()){
      setToken("")
      navigate(LANDING_PAGE.root)
      window.location.reload(true);
    }
    document.addEventListener('readystatechange', () => console.log(document.readyState));
  
    if (document.readyState === 'complete') {
        setLoadState(true)
    } else {
        setLoadState(false)
    }

    window.addEventListener("load", () => {
      setConnectionState(navigator.onLine);
    
      window.addEventListener("online", () => {
        // Set hasNetwork to online when they change to online.
        setConnectionState(true)
      });
    
      window.addEventListener("offline", () => {
        // Set hasNetwork to offline when they change to offline.
        setConnectionState(false)
      });
    });

      enqueueSnackbar('veuillez vérifier votre connexion internet ', { 
        hidden: iff(ConnectionState===false, false, true ),
        variant: 'error', anchorOrigin:{
        vertical: 'bottom',
        horizontal: 'right'
      }, persist: iff(ConnectionState===false, true, false ) , action: (key) =>{
        setsnckbarId(key)
        return <>
          <Button size="small" color="inherit" onClick={() =>  closeSnackbar(key)}>
            Fermer
          </Button>
        </>
       } })
      iff(snckbarId!==null , closeSnackbar(snckbarId),null)
    return () => { 
    
    }
  }, [ConnectionState, token, LoadState])
  return (
   
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
        >
        <Outlet />
      </MainStyle>
      

      {isFetching && ConnectionState===true?<Fab
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          backgroundColor: (theme) =>theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
        }} 
      >
      <CircularProgress /> 
      </Fab>: null} 
    </RootStyle>
  );
}
