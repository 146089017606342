import * as Yup from "yup";
import { useSnackbar } from "notistack5";
import { useEffect, useState } from "react";
// material
import { TextField, Box } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@material-ui/lab";
import {
  useGetGroupes,
  useAddToexistingGroupeGroupesForOneUser,
} from "../../../hooks/Api/useGroupes";

import useAuth from "../../../hooks/userAuth";
import { useAskForRecharge, usePaid } from "../../../hooks/Api/useSms";
import { regex, iff, RandomUid } from "../../../utils/utils";

const PayerCredit = ({ setisLoading, isLoading, handleClose, userData }) => {
  const { pathname } = useLocation();
  const user = useAuth();
  const { name, idGroupes } = useParams();
  console.log(name);
  console.log(idGroupes);
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const Payer = usePaid(auth.id);
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(regex, "Ce numero de télephone est invalide")
      .required("entrez votre numéro de télephone "),
    amount: Yup.string().required("Entrez le montant "),
  });

  const { handleSubmit, reset, control } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmitdata = (data) => {
    AskForPaid(data);
  };

  const AskForPaid = async (data) => {
    setisLoading(true);
    console.log(data);
    Payer.mutate(data, {
      onSuccess: (data) => {
        reset();
        enqueueSnackbar("depôt initie en attente de recharge ", {
          variant: "success",
          autoHideDuration: 6000,
        });
      },
    });
    setTimeout(() => {
      setisLoading(false);
      enqueueSnackbar("une erreur est survenue", {
        variant: "error",
        autoHideDuration: 6000,
      });
      handleClose();
    }, 1000);
  };
  return (
    <form>
      <Controller
        name="appTransRef"
        control={control}
        defaultValue={RandomUid()}
        render={() => <TextField type="hidden" value={RandomUid()} />}
      />
      <Controller
        name="country"
        control={control}
        defaultValue={auth.user.country}
        render={() => <TextField type="hidden" value={auth.user.country} />}
      />
      <Controller
        name="name"
        control={control}
        defaultValue={auth.user.first_name}
        render={() => <TextField type="hidden" value={auth.user.first_name} />}
      />
      <Controller
        name="email"
        control={control}
        defaultValue={auth.user.email}
        render={() => <TextField type="hidden" value={auth.user.email} />}
      />
      <Controller
        name="reason"
        control={control}
        defaultValue="depôt argent "
        render={() => <TextField type="hidden" value="depôt argent" />}
      />
      <Controller
        name="privateKey"
        control={control}
        defaultValue="bf24cbac-c9de-450b-b2af-8dadafdb880f"
        render={() => (
          <TextField
            type="hidden"
            value="bf24cbac-c9de-450b-b2af-8dadafdb880f"
          />
        )}
      />
      <Controller
        name="currency"
        control={control}
        defaultValue="XAF"
        render={() => <TextField type="hidden" value="XAF" />}
      />
      <Controller
        name="phoneNumberPrefix"
        control={control}
        defaultValue="237"
        render={() => <TextField type="hidden" value="237" />}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            label="Numéro du dépôt"
            margin="dense"
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        name="amount"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            label="cout a payer"
            margin="dense"
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />

      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton
          fullWidth
          size="md"
          onClick={handleSubmit(onSubmitdata)}
          variant="contained"
          loading={isLoading}
        >
          Payer cette recharge
        </LoadingButton>
      </Box>
    </form>
  );
};
export default PayerCredit;
