import { useState,useCallback } from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Stack,
  Switch,
  Container,
  CardHeader,
  Typography,
  CardContent,
  FormControlLabel
} from '@material-ui/core';
// components
import Page from '../../Page';
import { UploadMultiFileEtablissements } from '../../upload';


const UploadeFileEtablissements =({setisLoading,isLoading, handleClose, classe, idOfCUrrentUser})=>{
    const [file, setFile] = useState(null);
    const handleDropSingleFile = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        console.log(file);
        if (file) {
          setFile(file);
        }
      }, []);
     const handleRemove = () => {
        setFile(null);
    };
    return (
      <Container fullWidth  sx={{ marginTop:5}} spacing={2}>
                <UploadMultiFileEtablissements
                    files={file}
                    onDrop={handleDropSingleFile}
                    onRemove={handleRemove}
                    handleClose={handleClose}
                    classe={classe}
                    idOfCUrrentUser={idOfCUrrentUser}
                />
    </Container>
    )
};
export default UploadeFileEtablissements