import * as Yup from "yup";
import { useSnackbar } from "notistack5";

// material
import { TextField, Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@material-ui/lab";
import {
  useGetClasses,
  useAddContactsInClasses,
} from "../../../hooks/Api/useClasses";

import { regex } from "../../../utils/utils";

const AddToClasse = ({
  setisLoading,
  isLoading,
  handleClose,
  idOfCUrrentUser,
}) => {
  const { name, idGroupes } = useParams();
  const classes = useGetClasses(idGroupes);
  const addToClasse = useAddContactsInClasses(name);
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = Yup.object().shape({
    numeroParent: Yup.string()
      .matches(regex, "Ce numero de télephone est invalide")
      .required("entrez votre numéro de télephone "),
    Appelations: Yup.string().required("entrez l'appelation Ex:  Mr ou Mme "),
    nomElv: Yup.string().required("entrez le nom de l'élève "),
    niveauElv: Yup.string().required("entrez le niveau de l'élève "),
    classe: Yup.string().required("entrez la classe l'élève "),
    matriculeElv: Yup.string().required("entrez le matricule de l'élève "),
  });

  const { handleSubmit, reset, control } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmitdata = (data) => {
    SaveContacts(data);
  };

  const SaveContacts = async (data) => {
    setisLoading(true);
    console.log(data);
    addToClasse.mutate(data, {
      onSuccess: () => {
        reset();
        enqueueSnackbar("Ajout bien éffectuer", { variant: "success" });
        classes.refetch();
      },
    });
    setTimeout(() => {
      setisLoading(false);
      handleClose();
    }, 1000);
  };
  return (
    <form>
      <Controller
        name="matriculeElv"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            label="matricule de l'élève"
            margin="dense"
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        name="Appelations"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            label="Appelations"
            margin="dense"
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        name="nomElv"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            label="Nom de l'élève"
            margin="dense"
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        name="numeroParent"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            label="Numéro du parent"
            margin="dense"
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        name="niveauElv"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            label="Niveau de l'élève"
            margin="dense"
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        name="classe"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            label="classe de "
            margin="dense"
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />
      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton
          fullWidth
          size="md"
          onClick={handleSubmit(onSubmitdata)}
          variant="contained"
          loading={isLoading}
        >
          Ajouter
        </LoadingButton>
      </Box>
    </form>
  );
};
export default AddToClasse;
