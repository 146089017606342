import * as Yup from 'yup';
import {useSnackbar} from 'notistack5';
import { useParams, useLocation} from 'react-router-dom';
// material
import {
  TextField, Box} from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@material-ui/lab';

import {useUpdateContacts} from '../../../hooks/Api/useContacts';
import { regex } from '../../../utils/utils';
import { useGetGroupes } from '../../../hooks/Api/useGroupes';
 
// Get QueryClient from the context

const EditContacts =({setisLoading,isLoading, handleClose, id, contactData})=>{
    const { enqueueSnackbar} = useSnackbar();
    const { pathname } = useLocation();
    const { name, idGroupes } = useParams();
    console.log(name);
    console.log(idGroupes);
    const groupes= useGetGroupes(idGroupes);
    const isdetailGroupes = pathname.includes('detailsgroupes');
    const isinUserPart = pathname.includes('listes');
    console.log(isdetailGroupes)
    console.log(contactData)

    const EditerContacts= useUpdateContacts(contactData._id, id,handleClose, isdetailGroupes);
    const validationSchema = Yup.object().shape({
      phone:Yup.string().matches(regex, 'Ce numero de télephone est invalide')
      .required('entrez votre numéro de télephone '),
       });
    
    const { handleSubmit, reset, control, formState: { errors }} = useForm({
      resolver: yupResolver(validationSchema)
    });
    const onSubmitdata =(data) =>{
        SaveTarif(data) 
    };
    
    const SaveTarif= async(data)=>{
        setisLoading(true);
        console.log(data);
        EditerContacts.mutate(data,{
         onSuccess: async(data)=>{
           enqueueSnackbar('le contact a été modifié', { variant: 'success' });
           if(isdetailGroupes || isinUserPart){
            groupes.refetch()
           }
           reset();
         }
       });
       
        setTimeout(() => {
          setisLoading(false);
          handleClose()
        }, 1000);
        
      }    
    return (
    <form>
     <Controller
        name="userId"
        control={control}
        defaultValue={contactData._id}
        render={() => (
          <TextField  type="hidden" value={contactData._id} />
        )}
      />
      <Controller
    name="name"
    control={control}
    defaultValue={contactData.name}
     render={({ field, fieldState: { error } }) => (
              <TextField {...field}autoFocus fullWidth label="Nom" margin="dense" error={Boolean(error)} helperText={error?.message} />
    )}
    />
    <Controller
    name="phone"
    control={control}
    defaultValue={contactData.phone}
     render={({ field, fieldState: { error } }) => (
              <TextField {...field}autoFocus fullWidth label="Phone"  margin="dense"  error={Boolean(error)} helperText={error?.message} />
    )}
    />
    <Controller
    name="ville"
    control={control}
    defaultValue={contactData.ville}
     render={({ field, fieldState: { error } }) => (
              <TextField {...field}autoFocus fullWidth label="Ville"  margin="dense"  error={Boolean(error)} helperText={error?.message} />
    )}
    />
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
      <LoadingButton fullWidth size="md" onClick={handleSubmit(onSubmitdata)}   variant="contained" loading={isLoading}>
          Modifier
      </LoadingButton>
      <LoadingButton sx={{ml:2}} fullWidth size="md" color="warning" onClick={handleClose}   variant="contained">
          fermer
      </LoadingButton>
      </Box>
    </form>
    );
}
export default EditContacts