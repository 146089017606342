// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_AUTH = '/auth';
const ROOTS_PAGE = '/';
// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    pageOne: path(ROOTS_DASHBOARD, '/home'),
    pageTwo: path(ROOTS_DASHBOARD, '/two'),
    pageThree: path(ROOTS_DASHBOARD, '/three'),
    pageThrees: path(ROOTS_DASHBOARD, '/three')
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/app'),
    adminUserList: path(ROOTS_DASHBOARD, '/app/admin-userList'),
    allUser: path(ROOTS_DASHBOARD, '/app/all-user'),
    userList: path(ROOTS_DASHBOARD, '/app/userList'),
    schoolList: path(ROOTS_DASHBOARD, '/app/schoolList'),
    newUser: path(ROOTS_DASHBOARD, '/app/new'),
    editById: path(ROOTS_DASHBOARD, '/app/stephane/edit'),
    profil: path(ROOTS_DASHBOARD, '/app/stephane/profil'),
    requestAll: path(ROOTS_DASHBOARD, '/request-admin/all-request'),
    tarifAll: path(ROOTS_DASHBOARD, '/tarif/all'),
    // newlstetters: path(ROOTS_DASHBOARD, '/tarif/all'),
    // mail: path(ROOTS_DASHBOARD, '/tarif/all'),
    // chats: path(ROOTS_DASHBOARD, '/tarif/all'),
    // statsMongo: path(ROOTS_DASHBOARD, '/tarif/all'),
    // languges: path(ROOTS_DASHBOARD, '/tarif/all'),
    groupesProfilContacts: path(ROOTS_DASHBOARD, '/app/stephane/other/detailsgroupes'),
    claseContactsList: path(ROOTS_DASHBOARD, '/app/montesqiueu/other/classeDetails'),
  },
  app: {
    root: path(ROOTS_DASHBOARD, '/user'),
    contacts: path(ROOTS_DASHBOARD, '/user/contacts'),
    groupe: path(ROOTS_DASHBOARD, '/user/groupe'),
    historique: path(ROOTS_DASHBOARD, '/user/historique'),
    recharge: path(ROOTS_DASHBOARD, '/user/recharge'),
    profil: path(ROOTS_DASHBOARD, '/user/profil'),
    etablissement: path(ROOTS_DASHBOARD, '/user/etablissement'),
    paiements: path(ROOTS_DASHBOARD, '/user/paiements'),
    pageFive: path(ROOTS_DASHBOARD, '/app/userList'),
    pageSix: path(ROOTS_DASHBOARD, '/app/six')
  }
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/register')
};
export const LANDING_PAGE = {
  root: ROOTS_PAGE,
  contact: path(ROOTS_PAGE, '/contacs'),
  condition: path(ROOTS_PAGE, 'conditions'),
  police: path(ROOTS_PAGE, 'cookies'),
  pricing: path(ROOTS_PAGE, '/pricing'),
  faq: path(ROOTS_PAGE, '/faq')
};
