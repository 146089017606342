

import { useQuery,useQueryClient,useMutation } from 'react-query'
import { iff } from '../../utils/utils';
import axios from '../../utils/axios'

export  function useGetGroupes(id){
    return  useQuery(`/users/getGroups/${id}`,
    async () => {
      const { data } = await axios.get(`/users/getGroups/${id}`)
      return data?.data;
    },{
        enabled:  Boolean(id)
    }
    );
}
export  function useDeleteGroupes(id){
    const queryClient=useQueryClient();
    return  useMutation(`/users/deleteGroup/${id}`,
    async (idGroupe) => {
      const { data } = await axios.put(`/users/deleteGroup/${idGroupe}`)
      return data?.data;
    },{
        enabled: Boolean(id)
    },{
        onSuccess: function(newPost){ 
            if( queryClient.getQueryData(`/users/getGroups/${id}`)){
                queryClient.invalidateQueries(`/users/getGroups/${id}`,{
                    active:true
                  })
            }else{
                queryClient.setQueryData(`/users/getGroups/${id}`, newPost.data)
                queryClient.invalidateQueries(`/users/getGroups/${id}`,{
                    active:true
                  })
            }
            
        }
    }
    );
}
export  function useCreateGroupesForOneUser(id){
    const queryClient=useQueryClient();
    return  useMutation(`/users/createGroup/${id}`,
    async (newData) => {
      const { data } = await axios.post(`/users/createGroup/${id}`,newData)
      return data?.data;
    },{
        onSuccess: function(newPost){ 
            if( queryClient.getQueryData(`/users/getGroups/${id}`)){
                queryClient.invalidateQueries(`/users/getGroups/${id}`,{
                    active:true
                  })
            }else{
                queryClient.setQueryData(`/users/getGroups/${id}`, newPost.data)
                queryClient.invalidateQueries(`/users/getGroups/${id}`,{
                    active:true
                  })
            }
            
        }
    }
    );
}
export  function useAddToexistingGroupeGroupesForOneUser(id,idOfCurrentUser){
    const queryClient=useQueryClient();
    return  useMutation(`/users/modifyGroup/${id}`,
    async (newData) => {
      const { data } = await axios.put(`/users/modifyGroup/${id}`,newData)
      return data?.data;
    },{
        enabled: Boolean(id)
    },{
        onSuccess: function(newPost){ 
            console.log(newPost)
            if( queryClient.getQueryData(`/users/getGroups/${idOfCurrentUser}`)){
                queryClient.setQueryData(`/users/getGroups/${idOfCurrentUser}`, newPost.data)
                queryClient.invalidateQueries(`/users/getGroups/${idOfCurrentUser}`,{
                    active:true
                  })
            }else{
                queryClient.setQueryData(`/users/getGroups/${idOfCurrentUser}`, newPost.data)
                queryClient.invalidateQueries(`/users/getGroups/${idOfCurrentUser}`,{
                    active:true
                  })
            }
            
        }
    }
    );
}
export function useDeleteContactsInGroupes(id,idOfCurrentUser){
    console.log(id,idOfCurrentUser);
        const queryClient=useQueryClient();
        return useMutation((newPost) => axios
        .put(`/users/modifyGroup/${id}`, newPost)
        .then((res) => res.data),
        {
            onSuccess: newPost=>{
                console.log(newPost);
                queryClient.invalidateQueries(`/users/getGroups/${idOfCurrentUser}`,{
                    active:true
                  })
                
            },
            onError:(error, editedValue, rollback)=> rollback(),
            onSettled:(data, error, editedValue)=>{
              console.log(editedValue)
              console.log(data)
              queryClient.removeQueries([`/users/getGroups/${idOfCurrentUser}`, id])
              queryClient.invalidateQueries(`/users/getGroups/${idOfCurrentUser}`,{
                active:true
              })
          }
          
        }
        )
    }
