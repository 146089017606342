// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  users: getIcon('ic_users'),
  profil: getIcon('ic_profil'),
  history: getIcon('ic_history'),
  school: getIcon('ic_school'),
  recharge: getIcon('ic_recharge'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  price: getIcon('ic_price'),
  conditions: getIcon('ic_condition'),
  notification: getIcon('ic_notification'), 
  language: getIcon('ic_language'), 

};

export const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'MANAGE',
    items: [
      { title: 'Tableau de bord ', path: PATH_DASHBOARD.general.pageOne, icon: ICONS.dashboard },
      { title: 'Mes contacts', path: PATH_DASHBOARD.app.contacts, icon: ICONS.user },
      { title: 'Mes groupes', path: PATH_DASHBOARD.app.groupe, icon: ICONS.users },
      { title: 'Mon Historiques', path: PATH_DASHBOARD.app.historique, icon: ICONS.history},
      { title: 'Mes recharges', path: PATH_DASHBOARD.app.recharge, icon: ICONS.recharge },
      { title: 'Mes Paiements', path: PATH_DASHBOARD.app.paiements, icon: ICONS.price },
      { title: 'Mon profil', path: PATH_DASHBOARD.app.profil, icon: ICONS.profil }
    ]
  },
]; 
export const sidebarConfigeEtablissements = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'MANAGE',
    items: [
      { title: 'Tableau de bord ', path: PATH_DASHBOARD.general.pageOne, icon: ICONS.dashboard },
      { title: 'Mes contacts', path: PATH_DASHBOARD.app.contacts, icon: ICONS.user },
      { title: 'Mes groupes', path: PATH_DASHBOARD.app.groupe, icon: ICONS.users },
      { title: 'Mon Historiques', path: PATH_DASHBOARD.app.historique, icon: ICONS.history},
      { title: 'Mes recharges', path: PATH_DASHBOARD.app.recharge, icon: ICONS.recharge },
      { title: 'Mes Paiements', path: PATH_DASHBOARD.app.paiements, icon: ICONS.price },
      { title: 'Mon etablissement', path: PATH_DASHBOARD.app.etablissement, icon: ICONS.users },
      { title: 'Mon profil', path: PATH_DASHBOARD.app.profil, icon: ICONS.profil }
    ]
  },
]; 
export const sidebarConfigAdmin = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Tableau de bord', path: PATH_DASHBOARD.general.pageOne, icon: ICONS.dashboard },
      {
        title: 'users',
          path: PATH_DASHBOARD.app.root,
          icon: ICONS.user,
          children: [
            { title: 'All', path: PATH_DASHBOARD.admin.allUser },
            { title: 'Administrateurs', path: PATH_DASHBOARD.admin.adminUserList },
            { title: 'Users', path: PATH_DASHBOARD.admin.userList },
            { title: 'Etablissements', path: PATH_DASHBOARD.admin.schoolList },
            { title: 'AddUsers', path: PATH_DASHBOARD.admin.newUser },
            
          ]
      },
      {
        title: 'request',
          path: PATH_DASHBOARD.app.root,
          icon: ICONS.ecommerce,
          children: [
            { title: 'Recapitulatif', path: PATH_DASHBOARD.admin.requestAll },
            // { title: 'Requete orange', path: PATH_DASHBOARD.app.pageFour },
            // { title: 'Requete mtn', path: PATH_DASHBOARD.app.pageFive }
          ]
      },
      { title: 'conditions', path: PATH_DASHBOARD.general.pageThree, icon: ICONS.conditions },
      { title: 'tarifs', path: PATH_DASHBOARD.admin.tarifAll, icon: ICONS.price },
      // { title: 'newsletters', path: PATH_DASHBOARD.admin.newlstetters, icon: ICONS.notification },
      // { title: 'Mail', path: PATH_DASHBOARD.admin.mail, icon: ICONS.mail },
      // { title: 'Chats', path: PATH_DASHBOARD.admin.chats, icon: ICONS.chat },
    ]
  },
  // {
  //   subheader: 'Settings',
  //   items: [
  //     { title: 'StatMongoDb', path: PATH_DASHBOARD.admin.statsMongo, icon: ICONS.analytics },
  //     { title: 'langues', path: PATH_DASHBOARD.admin.languges, icon: ICONS.language }
  //   ]
  // }
];

export default {sidebarConfigAdmin, sidebarConfig, sidebarConfigeEtablissements};
