import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
import axios from './axios';
// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

 const handleTokenExpired = (expirationDate ) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const today = new Date();
  const endDate = new Date(expirationDate);
  const timeLeft= diffDays(new Date(today.getTime()), new Date(endDate.getTime()))
  console.log( );
  expiredTimer = window.setTimeout(() => {
     localStorage.clear();
  }, ((timeLeft * 86400)));
};

const diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('token', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    const { exp, expirationDate } = jwtDecode(accessToken);
   
    handleTokenExpired(expirationDate);
  } else {
    localStorage.setItem('token', "");
    delete axios.defaults.headers.common.Authorization;
    
  }
};

export { isValidToken, setSession, verify, sign };
