import { useQuery,useQueryClient,useMutation } from 'react-query'
import { iff } from '../../utils/utils';
import axios from '../../utils/axios'
import Axiosdownload from '../../utils/axiosForDownload'
import useAuth from '../userAuth';

export  function useGetClasses(id){
    console.log(id)
    return  useQuery(`/school/getClasses/${id}`,
    async () => {
      const { data } = await axios.get(`/school/getClasses/${id}`)
      return data?.data;
    },{
        enabled: Boolean(id)
    }
    );
}


export  function useDeleteClasses(id,idOfCurrentUser,setUpdate){
  const queryClient=useQueryClient();
    return  useMutation((newPost) => axios
    .delete(`/school/deleteClasses/${id}`)
    .then((res) => res.data),
    {
      onMutate: editevalues=>{
      //  console.log(editevalues)
      },
      onSuccess: editevalues=>{
        if(!queryClient.getQueryData(`/school/getClasses/${idOfCurrentUser}`)) return  queryClient.invalidateQueries(`/school/getClasses/${idOfCurrentUser}`);
         const previousValues= queryClient.getQueryData(`/school/getClasses/${idOfCurrentUser}`)
          // console.log(previousValues)
          // console.log(previousValues.length)
          const updatedValues= [...previousValues]
          const removeDeleted= updatedValues.filter((eachValues)=>eachValues._id!==id)
          setUpdate(removeDeleted)
        queryClient.invalidateQueries(`/school/getClasses/${idOfCurrentUser}`,{
          active:true
        })
      },
      onError:(error, editedValue, rollback)=> rollback(),
      onSettled:(data, error, editedValue)=>{
        // console.log(editedValue)
        // console.log(data)
        queryClient.invalidateQueries(`/school/getClasses/${idOfCurrentUser}`,{
          active:true
        })
    }
    
  }
  
    );
}
export function useUpdateContacts(id,idOfCurrentUser){
  const queryClient=useQueryClient();
  return useMutation((newPost) => axios
  .put(`/school/modifyContact/${id}`, newPost)
  .then((res) => res.data),
  {
    onSuccess: function(newPost){ 
        queryClient.invalidateQueries(`/school/getClasses/${idOfCurrentUser}`,{
          active:true
        })
        return newPost
    }
    
  }
  )
}
export  function useDeleteContactsInClasses(idcontacts,idOfCurrentUser){
  console.log(idcontacts,idOfCurrentUser);
  const queryClient=useQueryClient();
  return  useMutation(`/school/deleteContactManyClasses/${idcontacts}`,
  async (contacts) => {
    console.log(contacts)
    const { data } = await axios.delete(`/school/deleteContactManyClasses/${idcontacts}`,{ data: contacts } )
    return data?.data;
  },{
      onSuccess: function(newPost){ 
          if( queryClient.getQueryData(`/school/getClasses/${idOfCurrentUser}`)){
             // queryClient.setQueryData(`/school/getContacts/${idOfCurrentUser}`, newPost.data)
              queryClient.invalidateQueries(`/school/getClasses/${idOfCurrentUser}`,{
                active:true
              })
          }else{
            //  queryClient.setQueryData(`/school/getClasses/${idOfCurrentUser}`, newPost.data)
              queryClient.invalidateQueries(`/school/getClasses/${idOfCurrentUser}`, {
                active:true
              })
          }
          
      }
  }
  );
}
export  function useAddContactsInClasses(idcontacts){

  const queryClient=useQueryClient();
  return  useMutation(`/school/createContact/${idcontacts}`,
  async (contacts) => {
    const { data } = await axios.post(`/school/createContact/${idcontacts}`, contacts)
    return data?.data;
  },{
      onSuccess: function(newPost){ 
          if( queryClient.getQueryData(`/school/getClasses/${idcontacts}`)){
              queryClient.invalidateQueries(`/school/getClasses/${idcontacts}`,{
                active:true
              })
          }else{
              queryClient.setQueryData(`/school/getClasses/${idcontacts}`, newPost.data)
              queryClient.invalidateQueries(`/school/getClasses/${idcontacts}`,{
                active:true
              })
          }
          
      }
  }
  );
}
export  function useSendSmsToContactsFile(){
  return  useMutation(`/school/sendSmsToParentStudentsSchool`,
  async (contacts) => {
    const { data } = await axios.post(`school/sendSmsToParentStudentsSchool`, contacts)
    return data?.data;
  },{
      onError: (error)=>{ 
        console.log(error)
     }
  }
  );
}
export  function useDownloadFile(){
  return  useMutation(`/school/downloadFileTosendSmsToParentStudentsSchool`,
  async (contacts) => {
    const { data } = await axios.get(`school/downloadFileTosendSmsToParentStudentsSchool/${contacts}`,{
      responseType: 'blob',
   })
    return data;
  },{
      onError: (error)=>{ 
        console.log(error)
     }
  }
  );
}
export  function useDownloadFileForNote(id){
  return  useMutation(`/public/uploads/template_excel_etablissements.xlsx`,
  async (contacts) => {
    const { data } = await Axiosdownload.get(`/public/uploads/template_excel_etablissements.xlsx`,{
      responseType: 'blob',
   })
    return data;
  },{
      onError: (error)=>{ 
        console.log(error)
     }
  }
  );
}

export  function usePreviewContacts(id, setTrackUpload){
  const createEmployee = async (newPost) => {
    const { data } =await axios.post(`/school/ImportExcelFilToPreviewNoteOnClientSide/${id}`, newPost,{
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setTrackUpload(percentCompleted);
      }})
    return data;
  };
  return useMutation((newPost)=>createEmployee(newPost), {
    onSuccess: data => {
      console.log(data);
      const message = "success"
      return data;
    },
    onError: (e) => {
      console.log(e);
    },
  });
}

export  function usePreviewContactsEstablishment(id, setTrackUpload){
  const createEmployee = async (id, newPost) => {
    const { data } =await axios.post(`/school/studentsContactsSaveExcel/${id}`, newPost,{
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setTrackUpload(percentCompleted);
      }})
    return data;
  };
  return useMutation((newPost)=>createEmployee(id,newPost), {
    onSuccess: data => {
      console.log(data);
      const message = "success"
      return data;
    },
    onError: (e) => {
      console.log(e);
    },
  });
}
export  function useSaveContactsParent(){
  const user= useAuth()
  const queryClient=useQueryClient();
  return useMutation(`/school/saveParentContact`,
  async (contacts) => {
    const { data } = await axios.post(`/school/saveParentContact`, {"contactArray":contacts})
    return data?.data;
  },{
    onSuccess: function(newPost){ 
      console.log(newPost)
        if( queryClient.getQueryData(`/school/getClasses/${user.id}`)){
            queryClient.setQueryData(`/school/getClasses/${user.id}`, newPost)
            queryClient.invalidateQueries(`/school/getClasses/${user.id}`)
        }else{
          queryClient.setQueryData(`/school/getClasses/${user.id}`, newPost)
            queryClient.invalidateQueries(`/school/getClasses/${user.id}`)
        }
        
    }
  }
  );
}