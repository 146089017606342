import { log } from 'deck.gl';
import { useSnackbar } from 'notistack5';
import {useQueryClient,useMutation, useQuery } from 'react-query'
import axios from '../../utils/axios'
import {iff} from '../../utils/utils'
import useAuth from '../userAuth';

export  function useSendSms(){
     const queryClient=useQueryClient();
     const user= useAuth();
     const link =`/users/${iff(user.isAdmin()===true, "getHistoryAll","getHistory")}/${user.user.id}`;
      return useMutation(
        (values) => axios.post(`/sms`, values).then((res) => res.data)
        ,{
          onSuccess:()=>{
            queryClient.invalidateQueries(link)
          }
        }
      );
}

export  function useCheck(idOfCurrentUser){
  const queryClient=useQueryClient();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation((newPost) => axios
  .put(`/pay/CheckPaiement/${newPost.id}/${newPost.privatekey}`)
  .then((res) => res.data),
  {
    onSuccess: function(newPost){ 
      console.log(newPost);
      if(!queryClient.getQueryData(`/users/getHistoryRequest/${idOfCurrentUser}`)) return  queryClient.invalidateQueries(`/users/getHistoryRequest/${idOfCurrentUser}`);
      queryClient.invalidateQueries(`/users/getHistoryRequest/${idOfCurrentUser}`)
      queryClient.invalidateQueries(`/users/getUserData/${idOfCurrentUser}`)
    },
    onError: (error, _newPost, rollback) => {
      enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 6000  });
      if(!queryClient.getQueryData(`/users/getHistoryRequest/${idOfCurrentUser}`)) return  queryClient.invalidateQueries(`/users/getHistoryRequest/${idOfCurrentUser}`);
      queryClient.invalidateQueries(`/users/getHistoryRequest/${idOfCurrentUser}`)
      if (rollback) rollback()
    },
  }
  )
}
export  function usePaid(idOfCurrentUser){
      const queryClient=useQueryClient();
      return useMutation(
        (values) => axios.post(`/pay/genericCheckPaymentStatus`, values).then((res) => res.data)
        ,{
          onMutate: editevalues=>{
           console.log(editevalues)
          },
          onSuccess: (editevalues)=>{
            console.log(editevalues)
            queryClient.invalidateQueries(`/users/getUserData/${idOfCurrentUser}`)
            
          },
          onError:(error, editedValue, rollback)=> rollback(),
          onSettled:(data, error, editedValue)=>{
            console.log(editedValue)
            console.log(data)
            // queryClient.invalidateQueries(`/users/getHistoryRequest/${idOfCurrentUser}`)
        }
        
      });
}

export function usePaiementsHistoryOfOneUser(id,load=true){
  const link = `/transaction/getTransactionByUser/${id}`
  return useQuery(link,
  async () => {
    const { data } = await axios.get(link)
    return data.data;
  },
  {
    enabled: Boolean(load),
  }
  );
}
export  function useAskForRecharge(idOfCurrentUser){
      const queryClient=useQueryClient();
      const { enqueueSnackbar } = useSnackbar();
      return useMutation(
        (values) => axios.post(`/sms/requestBuySms`, values).then((res) => res.data)
        ,{
          onMutate: editevalues=>{
           console.log(editevalues)
          },
          onSuccess: editevalues=>{
            if(!queryClient.getQueryData(`/users/getHistoryRequest/${idOfCurrentUser}`)) return  queryClient.invalidateQueries(`/users/getHistoryRequest/${idOfCurrentUser}`);
            queryClient.invalidateQueries(`/users/getHistoryRequest/${idOfCurrentUser}`)
            return editevalues;
          },
          onError: (error, _newPost, rollback) => {
            if(error.operatorResponse!==null){
              enqueueSnackbar(error.operatorResponse.message, { variant: 'error', autoHideDuration: 6000  });
            }else{
              enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 6000  });
            }
            if (rollback) rollback()
          },
          onSettled:(data, error, editedValue)=>{
            console.log(editedValue)
            console.log(data)
            queryClient.invalidateQueries(`/users/getHistoryRequest/${idOfCurrentUser}`)
        }
        
      });
}