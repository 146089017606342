import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

export const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to="/dashboard/home" replace /> },
        { path: 'home', element: <GeneralApp /> },
        { path: 'two', element: <PageTwo /> },
        { path: 'three', element: <PageThree /> },
        {
          path: 'app',
          children: [
            {
              path: '',
              element: <Navigate to="dashboard/app/four" replace />
            },
            { path: 'all-user', element: <AllUser /> },
            { path: 'admin-userList', element: <AdminList /> },
            { path: 'userList', element: <UserList /> },
            { path: 'schoolList', element: <SchoolUser /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: ':name/profil', element: <UserProfile /> },
            { path: ':name/:idGroupes/detailsgroupes', element: <ProfileGroupesContactsList /> },
            { path: ':name/:idGroupes/classeDetails', element: <ProfilEtabListItem /> }
          ]
        },
        {
          path: 'request-admin',
          children: [
            {
              path: '',
              element: <Navigate to="dashboard/request-admin/all" replace />
            },
            { path: 'all-request', element: <Recharges /> },
           
          ]
        },
        {
          path: 'tarif',
          children: [
            { path: 'all', element: <Tarifs /> },
           
          ]
        },
        {
          path: 'user',
          children: [
            {
              path: '',
              element: <Navigate to="dashboard/user/index" replace />
            },
            { path: 'contacts', element: <ProfilContactForUser/>  },
            { path: 'groupe', element: <ProfilgroupeForUser/>  },
            { path: 'historique', element: <ProfileHistoriquesForUser/>  },
            { path: 'recharge', element: <ProfilHistoriquesRechargeForUser/>  },
            { path: 'paiements', element: <ProfilPaiementRechargeForUser/>  },
            { path: 'profil', element: <UserAccountForUser/>  },
            { path: 'etablissement', element: <ProfilEtabListItemForUser/>  },
            { path: ':name/:idGroupes/etablissements', element: <ProfilEtabListItem /> },
            { path: ':name/:idGroupes/listes', element: <ProfileGroupesContactsListForUser /> },
            { path: ':name/:idGroupes/classeDetails', element: <ProfileEtabClasseDetails /> }
          ]
        },
      ]
    },


    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'conditions', element: <ConditionForHome /> },
        { path: 'cookies', element: <Policy /> }
      ]
    },
    
    { path: 'auth/login', element: <Login /> },
    { path: 'auth/register', element: <Register isEdit={false} /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
 
// IMPORT COMPONENTS
 
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
const PageThree = Loadable(lazy(() => import('../pages/PageThree')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Main
const ConditionForHome = Loadable(lazy(() => import('../pages/conditionForHome')));
const Policy = Loadable(lazy(() => import('../pages/policy')));
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
// User-Admin
const Login = Loadable(lazy(() => import('../pages/authentication/login')));
const Register = Loadable(lazy(() => import('../pages/authentication/register')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/users/SimpleUserList')));
const AdminList = Loadable(lazy(() => import('../pages/dashboard/users/AdminUserList')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/users/UserCreate')));
const SchoolUser = Loadable(lazy(() => import('../pages/dashboard/users/SchoolUser')));
const AllUser = Loadable(lazy(() => import('../pages/dashboard/users/AllUsers')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/users/UserProfile')));

// Reauest-admnin
const Recharges = Loadable(lazy(() => import('../pages/dashboard/recharges/Recharges')));
const Tarifs = Loadable(lazy(() => import('../pages/dashboard/Tarifs/Tarifs')));
const ProfileGroupesContactsList = Loadable(lazy(() => import('../components/_dashboard/user/profile/ProfileGroupesContactsList')));
const ProfilEtabListItem = Loadable(lazy(() => import('../components/_dashboard/user/profile/ProfilEtabListItem')));
// for user only
const ProfilContactForUser = Loadable(lazy(() => import('../components/_dashboard/user/profile/ProfilContactForUser')));
const ProfilgroupeForUser = Loadable(lazy(() => import('../components/_dashboard/user/profile/ProfilgroupeForUser')));
const ProfileHistoriquesForUser = Loadable(lazy(() => import('../components/_dashboard/user/profile/ProfileHistoriquesForUser')));
const ProfileGroupesContactsListForUser = Loadable(lazy(() => import('../components/_dashboard/user/profile/ProfileGroupesContactsListForUser')));
const ProfilHistoriquesRechargeForUser = Loadable(lazy(() => import('../components/_dashboard/user/profile/ProfilHistoriquesRechargeForUser')));
const ProfilPaiementRechargeForUser = Loadable(lazy(() => import('../components/_dashboard/user/profile/ProfilPaiementRechargeForUser')));
const UserAccountForUser = Loadable(lazy(() => import('../pages/dashboard/users/UserAccount')));
const ProfilEtabListItemForUser = Loadable(lazy(() => import('../components/_dashboard/user/profile/ProfilEtabListItemForUser')));
const ProfileEtabClasseDetails = Loadable(lazy(() => import('../components/_dashboard/user/profile/ProfileEtabClasseDetails')));
