import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions,Icon, DialogContent, DialogContentText, Button, DialogTitle, IconButton } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { LoadingButton } from '@material-ui/lab';
import { makeStyles } from "@material-ui/styles";
import CloseIcon from '@material-ui/icons/Close';

import { iff } from '../../utils/utils';
import Crediter from './sub_components_admin/Crediter';
import EditTarif from './sub_components_admin/EditTarif';
import EditContacts from './sub_components_admin/EditContacts';
import AddContacts from './sub_components_admin/AddContacts';
import UploadeFile from './sub_components_admin/UploadeFile';
import PreviewContacts from './sub_components_admin/PreviewContacts';
import CreateGroupes from './sub_components_admin/createGroupes';
import AddToGroupe from './sub_components_admin/addToSpecificGroupe';
import AddToClasse from './sub_components_admin/AddToClasse';
import PreviewCLasseData from './sub_components_admin/PreviewCLasseData';
import UploadeFileEtablissements from './sub_components_admin/UploadeFileEtablissements';
import UploadeFileEtablissementsForAddingContacts from './sub_components_admin/UploadeFileEtablissementsForAddingContacts';
import PreviewCLasseDataForEstablishments from './sub_components_admin/PreviewCLasseDataForEstablishments';
import UploadMultiFileEtablissements from '../upload/UploadMultiFileSendSmsForEtab';
import DemanderUneRecharge from './sub_components_admin/DemanderUneRecharge';
import SubAccount from './sub_components_admin/SubAccount';
import EditClasse from './sub_components_admin/EditClasse';
import PayerCredit from './sub_components_admin/payeCredit';

const DisplayComponents=({typeOfComponents, props, isLoading, setisLoading, handleClose})=>{
  let components;
   if(typeOfComponents==="crediter" && props?.creditForm!==null){
    components= <Crediter userData={props?.creditForm} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="EditTarif" && props?.tarifForm!==null){
    components= <EditTarif tarif={props?.tarifForm} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="editerContacts" && props?.editForm!==null){
    components= <EditContacts contactData={props?.editForm} id={props?.id} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="AddContacts" && props?.idCurrentUser!==null){
    components= <AddContacts idOfCUrrentUser={props?.idCurrentUser} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="Upload" && props?.idCurrentUser!==null){
    components= <UploadeFile idOfCUrrentUser={props?.idCurrentUser} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="PreviewFIles" && props?.idCurrentUser!==null){
    components= <PreviewContacts idOfCUrrentUser={props?.idCurrentUser} contacts={props?.data} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="CreateObject" && props?.idCurrentUser!==null){
    components= <CreateGroupes idOfCurrentUser={props?.idCurrentUser} contactsArray={props?.data} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="AddGroupes" && props?.idCurrentUser!==null){
    components= <AddToGroupe idOfCurrentUser={props?.idCurrentUser} contactsArray={props?.data} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="AddToClasse" && props?.idCurrentUser!==null){
    components= <AddToClasse idOfCurrentUser={props?.idCurrentUser} contactsArray={props?.data} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="AddToClassPreviewFiles" && props?.idCurrentUser!==null){
    components= <PreviewCLasseData idOfCurrentUser={props?.idCurrentUser} classe={props?.data} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="updaloadFIleEtablissements" && props?.idCurrentUser!==null){
    components= <UploadeFileEtablissements idOfCurrentUser={props?.idCurrentUser} classe={props?.classe} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="demanderlecredit" && props?.idCurrentUser!==null){
    components= <DemanderUneRecharge idOfCurrentUser={props?.idCurrentUser} classe={props?.classe} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="subaccount" && props?.idCurrentUser!==null){
    components= <SubAccount idOfCurrentUser={props?.idCurrentUser} classe={props?.classe} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="editClasse" && props?.idCurrentUser!==null){
    components= <EditClasse idOfCurrentUser={props?.idCurrentUser} classe={props?.classe} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }else if(typeOfComponents==="importYourContacts" && props?.idCurrentUser!==null){
     components= <UploadeFileEtablissementsForAddingContacts idOfCurrentUser={props?.idCurrentUser} classe={props?.classe} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
    }else if(typeOfComponents==="PreviewDataForEstablishmentBeforeUpload" && props?.idCurrentUser!==null){
     console.log(props)
    components= <PreviewCLasseDataForEstablishments  idOfCUrrentUser={props?.idCurrentUser} classe={props?.data} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading}  />
   }else if(typeOfComponents==="payerCredit" && props?.idCurrentUser!==null){
     console.log(props)
    components= <PayerCredit userData={props?.idCurrentUser} isLoading={isLoading} handleClose={handleClose} setisLoading={setisLoading} />
   }
   return components;
} 

const useStyles = makeStyles(theme => ({
  paper: {
    overflowY: 'unset',
  },
  customizedButton: {
    position: 'absolute',
    left: '95%',
    top: '-9%',
    backgroundColor: 'lightgray',
    color: 'gray',
  }
}));
const ModalDialog=({ open,
     handleClose, 
     Component, 
     title, 
     description, 
     isUseComponents=false, 
     showCancelButton=false,
     showOkButton=false,
     buttonText,
     cancelText, 
     useActionButtons,
     size,
     isFullScreen=false,
     isLoadingVar=false,
     isLoadingForNonneComponents=false,
     otherProps,
     functionToExecute,
     typeOfComponents="crediter"
     })=>{
    console.log(handleClose);
    console.log(isLoadingForNonneComponents);
    const [isLoading, setisLoading] = useState(isLoadingVar);
    const classes = useStyles();
    console.log(isLoading)
  useEffect(() => {
    setisLoading(isLoadingVar)
    return () => {}
  }, [isLoadingVar, isLoadingForNonneComponents])
	return (<>
    {Component!==undefined? Component: null}
		<Dialog  classes={{paper: classes.paper}} open={open} onClose={handleClose} fullWidth maxWidth={size!=null? size: "xs"}>
		 <DialogTitle>
     <Box display="flex" alignItems="center">
                <Box flexGrow={1} >{title}</Box>
                <Box>
                <IconButton color="inherit" edge="start" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
                </Box>
          </Box>
      
      
     </DialogTitle>
    
        <DialogContent>
         {description!==null? <DialogContentText >
            {description}
          </DialogContentText> : null}
         {isUseComponents===true ? 
         <DisplayComponents  
         typeOfComponents={typeOfComponents}
         isLoading={isLoading}
         setisLoading={setisLoading}
         handleClose={handleClose}
         props={otherProps}
         /> : null}
        </DialogContent>
       {useActionButtons!==null?
        <DialogActions>
            {showCancelButton===true?
            iff(isLoading===true,
            <LoadingButton fullWidth  size="large" type="submit"  variant="contained" loading={isLoading}>
           chargement
          </LoadingButton> ,<Button onClick={handleClose} color="inherit">
            {cancelText!==null? cancelText: "Cancel"}
            </Button>
            )
        : null}
            {showOkButton===true?
             iff(isLoading===true,  <LoadingButton fullWidth size="large" type="submit"  variant="contained" loading={isLoading}>
            chargement
          </LoadingButton>,  <LoadingButton  size="sm" type="submit"  variant="contained" loading={isLoadingForNonneComponents} onClick={ async()=>{
            await functionToExecute().then(()=>{
              if(isLoadingForNonneComponents===false){
                handleClose()
              }
            }
            )
          }} >
             {buttonText!==null? buttonText : "Ok"}
                </LoadingButton> )
        : null}
        </DialogActions> : null} 
        </Dialog>
        </>
	);
};

export default ModalDialog;