import { useMutation,useQueryClient, useQuery, queryCache } from 'react-query';
import { useSnackbar } from 'notistack5';
import axios from '../../utils/axios';
import useAuth from '../userAuth';
import { iff } from '../../utils/utils';

export  function useCreateUser() {
  const auth = useAuth();
  const queryClient=useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (values) => axios.put(`/users/updateProfil/${auth.id}`, values).then((res) => res.data),
    {
      onSuccess: function(newPost){ 
        queryClient.invalidateQueries(`/users/getAllUserRegistered/${auth.id}`)
     },
      onError: (error, _newPost, rollback) => {
        enqueueSnackbar(error, { variant: 'error' });
        if (rollback) rollback()
      },
     
    }
  )
}
export  function useUpdateUser() {
  const auth = useAuth();
  const queryClient=useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (values) => axios.put(`/users/updateProfil/${auth.id}`, values).then((res) => res.data),
    {
      onSuccess: function(newPost){ 
      
        auth.setUserDataOnly(newPost.data)
        if(!queryClient.getQueryData(`/users/getUserData/${auth.id}`)) return  queryClient.invalidateQueries(`/users/getUserData/${auth.id}`);
        queryClient.invalidateQueries(`/users/getUserData/${auth.id}`)
     },
      onError: (error, _newPost, rollback) => {
        enqueueSnackbar(error, { variant: 'error' });
        if (rollback) rollback()
      },
     
    }
  )
}
export  function useCreateSubAccountUser() {
  const auth = useAuth();
  const queryClient=useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (values) => axios.post(`/users/register/${auth.id}`, values).then((res) => res.data),
    {
      onSuccess: function(newPost){ 
        console.log(newPost);
        auth.setUserDataOnly(newPost.data)
        if(!queryClient.getQueryData(`/users/getUserData/${auth.id}`)) return  queryClient.invalidateQueries(`/users/getUserData/${auth.id}`);
         queryClient.invalidateQueries(`/users/getUserData/${auth.id}`)
     },
      onError: (error, _newPost, rollback) => {
        enqueueSnackbar(displayMessage(error.message), { variant: 'error' });
        if (rollback) rollback()
      },
     
    }
  )
}
export  function useCreateUserAccount(setisRegister) {
  const auth = useAuth();
  const queryClient=useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (values) => axios.post(`/users/register`, values).then((res) => res.data),
    {
      onSuccess: function(newPost){ 
        console.log(newPost);
        setisRegister(true)
     },
      onError: (error, _newPost, rollback) => {

        enqueueSnackbar(displayMessage(error.message), { variant: 'error' });
        if (rollback) rollback()
      },
     
    }
  )
}
function displayMessage(data){
  let string="";
  if(data.includes('Email already in use')){
    string= "Cet email est déja pris"
  }else if(data.includes('Email already in use')){
    string= "Ce numéro de téléphone est déja utiliser"
  }else if(data.includes('sender_sms_name is already in use.')){
    string= 'sender_sms_name is already in use.'
  }else if(data.includes("phone is already in use .")){
    string= "ce numéro est déja utiliser"
  }else{
    string= data
  }
  return string;
}
export  function useDeleteSubAccountUser() {
  const auth = useAuth();
  const queryClient=useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (values) => axios.put(`/users/modifySubAccount/${auth.id}`, {'listContact': values}).then((res) => res.data),
    {
      onSuccess: function(newPost){ 
        console.log(newPost);
        auth.setUserDataOnly(newPost.data)
        if(!queryClient.getQueryData(`/users/getUserData/${auth.id}`)) return  queryClient.invalidateQueries(`/users/getUserData/${auth.id}`);
         queryClient.invalidateQueries(`/users/getUserData/${auth.id}`)
     },
      onError: (error, _newPost, rollback) => {
        enqueueSnackbar(error.error, { variant: 'error' });
        if (rollback) rollback()
      },
     
    }
  )
}

export async function myQuery(query,url) {
  try {
    const res = await axios.get(url, {
      params: { query },
    });
    return res.data;
  } catch {
    return null;
  }
}
export  function useGetUser(id, load, setLoadState){
  const auth = useAuth();
  const queryClient=useQueryClient();
 console.log(id)
 console.log(load)
 console.log("00000000000000000000000000000000")
 // react-query
 return useQuery(
  [`/users/getUserData/${id}`, { id }],
  () => {
    if (id ) {
      return myQuery(id,`/users/getUserData/${id}`)
    }
  },{
    onSuccess: function(user){ 
      if(user){
       console.log(user)
       auth.setUserDataOnly(user.data);
       queryClient.setQueryData(`/users/getUserData/${id}`, user.data)
     }
     setLoadState(false)
   },
  });
}

export  function useGetUserData() {
  const auth = useAuth();
  return useQuery(
    (values) => axios.get(`/users/getUserData/${auth.id}`, values).then((res) => res.data),
    {
      onSuccess: function(user){ 
        auth.setUserDataOnly(user)
     },
      onError: (error, _newPost, rollback) => {
        if (rollback) rollback()
      },
     
    }
  )
}
export default { useCreateUser}

