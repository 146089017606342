import { createContext, useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import {useNavigate,useLocation} from 'react-router-dom';

import useLocalStorage from '../hooks/useLocalStorage';
import { isValidToken, setSession } from '../utils/jwt';

const initialState = {
    isLogin: false,
    token: null,
    user: null,
    id: '',
    isAuthenticated: false,
    isInitialized: false,
  };

const AuthContext = createContext({
  ...initialState,
  setUser: () => Promise.resolve(),
  setUserDataOnly: () => Promise.resolve(),
  setCredit: () => Promise.resolve(),
  setToken: () => Promise.resolve(),
  isAdmin: () => Promise.resolve(),
  getCredit: () => Promise.resolve(),
  getToken: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});


AuthProvider.propTypes = {
    children: PropTypes.node
  };
  
  
  function AuthProvider({ children }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isLoginPage = pathname.includes('auth/login');
    const isAdminPage = pathname.includes('dashboard');
    const isRegisterPage = pathname.includes('auth/register');
    const [islLoginState, setIslLoginState] = useState(isAdminPage && (localStorage.getItem("token")==="" || localStorage.getItem("token")===null))
    const [settings, setSettings] = useLocalStorage('settings', {
        isLogin: initialState.isLogin,
        token: initialState.token,
        user: initialState.user,
        id: initialState.id
    });
  
    useEffect(() => {
      const initialize = async () => {
        try {
        
          if(settings.isLogin===true && settings.token!==null && isValidToken(settings.token)) {
            setSession(settings.token);
          } else {
            setSession(null);
            setSettings({
              ...settings,
              user: null,
              id: null,
              isLogin:false,
              token: null,
            });
          
          }
        } catch (err) {
          console.error(err);
          setSettings({
            ...settings,
            user: null,
            id: null,
            isLogin:false,
            token: null,
          });
        }
      };
  
      initialize();
    }, [islLoginState]);
    const setUser = (users, token) => {
      console.log(token);
        setSettings({
          ...settings,
          user: users,
          id: users._id,
          isLogin:true,
          token: token,
        });
      localStorage.setItem("token", token)
    };
    const setUserDataOnly = (users) => {
        setSettings({
          ...settings,
          user: users,
          id: users._id,
        });
    };
    const setCredit = (credit) => {
        setSettings({
          ...settings,
          user: credit
        });
    };
    const setToken = (tokenValue) => {
      setSettings({
        ...settings,
        token: tokenValue,
      });
      console.log(tokenValue);
    };
    const isAdmin=()=>  settings?.user?.isAdmin===null? null :  settings?.user?.isAdmin
    const getCredit=()=> settings?.user?.credit_sms===null? null : settings?.user?.credit_sms
    const getToken=()=> settings?.token===null? null :settings?.token
    const logout=()=>{
      setSettings({
      ...settings,
      user: null,
      id: null,
      isLogin:false,
      token: null,
    });
    localStorage.setItem("token", "")
  }
    return (
      <AuthContext.Provider
        value={{
          ...settings,
          // Mode
          setUser,
          setUserDataOnly,
          // Direction
          setCredit,
          // Color
          setToken,
          isAdmin,
          getCredit,
          getToken,
          logout
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
export { AuthProvider, AuthContext };
