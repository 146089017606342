import { useState } from 'react';
import { HotTable } from '@handsontable/react';
import {useSnackbar} from 'notistack5';
import 'handsontable/dist/handsontable.full.css';
import {Button, Container, Stack} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import  {useSaveContacts} from "../../../hooks/Api/useContacts"

const PreviewContacts=({idOfCUrrentUser, setisLoading, contacts, isLoading, handleClose,})=>{
    console.log(contacts);
    const [values, setvalues] = useState(contacts);
    const saveContacts=useSaveContacts(idOfCUrrentUser);
    const { enqueueSnackbar} = useSnackbar();

    const handleHOTChange=(changes, source)=>{
        console.log(changes);
        setvalues(values)
      }
    const uploadfile=async()=>{
        if(values!==null && values!==[]){
           sendData(values);
        }else{
            console.log("fljhfdksjf");
        }
    }
    const sendData=(data)=>{
        saveContacts.mutate(data,{
            onSuccess: (data) =>{
                enqueueSnackbar('importation reussit', { variant: 'success' });
                handleClose()
            },
            onError: (e) => {
              console.log(e);
              enqueueSnackbar('oops une erreur est survenu ', { variant: 'error' });
            },
          })
    }
    return(
        <Container   >
         <Stack  sx={{ height: '100%', marginTop:5, width:'100%', display: 'flex' }} spacing={2} direction="column" justifyContent="space-between">
             <HotTable
            data={values}
            id="hot"
            width="100hv"
            stretchH='all'
            contextMenu={true}
            colHeaders={['name', 'phone', 'ville']}
            rowHeaders= {true}
            hiddenColumns={{
                columns:[3]
              }}
            licenseKey="non-commercial-and-evaluation"
            onAfterChange={(changes, source) => handleHOTChange(changes, source)}
            />
             <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={saveContacts.isLoading}   onClick={()=>{uploadfile()}} >
              Importer
            </LoadingButton>

         </Stack>
        </Container>
    )
}
export default PreviewContacts