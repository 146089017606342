import * as Yup from "yup";
import { useState } from "react";
import { useSnackbar } from "notistack5";
import { useNavigate } from "react-router-dom";
// material
import { TextField, Box } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@material-ui/lab";

import useAuth from "../../../hooks/userAuth";
import { useUpdateTarif } from "../../../hooks/Api/useTarif";

const EditTarif = ({ setisLoading, isLoading, handleClose, tarif }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const tarifs = useUpdateTarif();
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();

  const validationSchema = Yup.object().shape({
    min: Yup.string().required(" entrer le nombre d'sms minimum"),
    description: Yup.string().required(" entrer lqdescription"),
    max: Yup.string().required("entrer le nombre d'sms maximun "),
    cost: Yup.string().required("entrer le prix du sms pour cette tranche "),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmitdata = (data) => {
    SaveTarif(data);
  };

  const SaveTarif = async (data) => {
    setisLoading(true);
    console.log(data);
    console.log({ id: tarif._id, data: data });
    tarifs.mutate({ id: tarif._id, data: data });
    if (tarifs.isSuccess) {
      reset();
      enqueueSnackbar("Mise à jour éffectuer", { variant: "success" });
    }
    setTimeout(() => {
      setisLoading(false);
      handleClose();
    }, 1000);
  };
  return (
    <form>
      <Controller
        name="min"
        control={control}
        defaultValue={tarif.min}
        render={({ field: { onChange, value } }) => (
          <TextField
            autoFocus
            fullWidth
            error={Boolean(errors.min)}
            helperText={errors.min}
            margin="dense"
            onChange={onChange}
            value={value || ""}
            label="Min"
          />
        )}
      />
      <Controller
        name="max"
        control={control}
        defaultValue={tarif.max}
        render={({ field: { onChange, value } }) => (
          <TextField
            autoFocus
            fullWidth
            error={Boolean(errors.max)}
            helperText={errors.max}
            margin="dense"
            onChange={onChange}
            value={value || ""}
            label="Max"
          />
        )}
      />
      <Controller
        name="cost"
        control={control}
        defaultValue={tarif.cost}
        render={({ field: { onChange, value } }) => (
          <TextField
            autoFocus
            fullWidth
            error={Boolean(errors.cost)}
            helperText={errors.cost}
            margin="dense"
            onChange={onChange}
            value={value || ""}
            label="Coût"
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        defaultValue={tarif.description}
        render={({ field: { onChange, value } }) => (
          <TextField
            autoFocus
            fullWidth
            error={Boolean(errors.description)}
            helperText={errors.description}
            margin="dense"
            onChange={onChange}
            value={value || ""}
            label="Description"
          />
        )}
      />
      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton
          fullWidth
          size="md"
          onClick={handleSubmit(onSubmitdata)}
          variant="contained"
          loading={isLoading}
        >
          Modifier
        </LoadingButton>
      </Box>
    </form>
  );
};
export default EditTarif;
