import * as Yup from 'yup';
import {useSnackbar} from 'notistack5';
import {useEffect, useState} from "react";
// material
import {
  TextField, Box} from '@material-ui/core';
import { useLocation,useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@material-ui/lab';
import { useGetClasses, useUpdateContacts } from '../../../hooks/Api/useClasses';
import useAuth from '../../../hooks/userAuth';
import { regex } from '../../../utils/utils';

const EditClasse =({setisLoading,isLoading, handleClose, idOfCUrrentUser, classe})=>{
  const { name, idGroupes } = useParams();
  const classes= useGetClasses(idGroupes);
  const user= useAuth();
  const addToClasse=useUpdateContacts(classe._id, idGroupes);
    const { enqueueSnackbar } = useSnackbar();
    const validationSchema = Yup.object().shape({
      numeroParent:Yup.string().matches(regex, 'Ce numero de télephone est invalide')
      .required('entrez votre numéro de télephone '),
      Appelations:Yup.string().required('entrez lappelation Ex:  Mr ou Mme '),
      nomElv:Yup.string().required('entrez le nom de l\'élève'),
      matriculeElv:Yup.string().required('entrez le matricule de l\'élève '),
       });
    
    const { handleSubmit, reset, control, } = useForm({
      resolver: yupResolver(validationSchema)
    });
    const onSubmitdata =(data) =>{
      SaveContacts(data) 
    }; 
   
    const SaveContacts= async(data)=>{
        setisLoading(true);
        console.log(data);
        data.classe=classe.classe
        data.niveauElv=classe.niveauElv
        addToClasse.mutate(data,{
          onSuccess:(data)=>{
            reset();
            enqueueSnackbar('le contact a été modifié', { variant: 'success' });
            classes.refetch();
          }
        });
        setTimeout(() => {
          setisLoading(false);
          handleClose()
        }, 1000);
        
      }    
    return (
    <form>
      <Controller
    name="matriculeElv"
    defaultValue={classe.matriculeElv}
    control={control}
     render={({ field, fieldState: { error } }) => (
              <TextField {...field}autoFocus fullWidth label="matricule de l'élève" margin="dense" error={Boolean(error)} helperText={error?.message} />
    )}
    />
    <Controller
    name="Appelations"
    control={control}
    defaultValue={classe.Appelations}
     render={({ field, fieldState: { error } }) => (
              <TextField {...field}autoFocus fullWidth label="Appelations"  margin="dense"  error={Boolean(error)} helperText={error?.message} />
    )}
    />
    <Controller
    name="nomElv"
    control={control}
    defaultValue={classe.nomElv}
     render={({ field, fieldState: { error } }) => (
              <TextField {...field}autoFocus fullWidth label="Nom de l'élève"  margin="dense"  error={Boolean(error)} helperText={error?.message} />
    )}
    />
    <Controller
    name="numeroParent"    
    defaultValue={classe.numeroParent}
    control={control}
     render={({ field, fieldState: { error } }) => (
              <TextField {...field}autoFocus fullWidth label="Numéro du parent"  margin="dense"  error={Boolean(error)} helperText={error?.message} />
    )}
    />
   
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
      <LoadingButton fullWidth size="md" onClick={handleSubmit(onSubmitdata)}   variant="contained" loading={isLoading}>
          Modifier
        </LoadingButton>
      </Box>
    </form>
    );
}
export default EditClasse