import SvgIconStyle from "../components/SvgIconStyle";

 export function isOrangePhoneNumber (phonenumber){
    const regexOrange = /^(((00)?237)?|\+(237)?)(65[5-9]\d{6}$|69\d{7}$)$/;
    return phonenumber.match(regexOrange)
  }
  
  export function isMTNPhoneNumber (phonenumber){
    const regexMTN = /^(((00)?237)?|\+(237)?)(6(5|8)[0-4]\d{6}$|67\d{7}$)$/
    return phonenumber.match(regexMTN)
  }
  export function isNexTelPhoneNumber (phonenumber){
    const regexNexttel = /^(((00)?237)?|\+(237)?)(66\d{7}$|68[5-9]\d{6}$)$/
    return phonenumber.match(regexNexttel)
  }
  export function iscamtelPhoneNumber (phonenumber){
    const regexCamtel = /^(((00)?237)?|\+(237)?)(24\d{7}$|620\d{6}$)$/
    return phonenumber.match(regexCamtel)
  }

export function checkPhoneValidation(phoneNumber){

  let result;
   if(
    !isOrangePhoneNumber(phoneNumber)&&
    !isMTNPhoneNumber(phoneNumber)&&
    !isNexTelPhoneNumber(phoneNumber)&&
    !iscamtelPhoneNumber(phoneNumber)
   ){
    // console.log(phoneNumber+"xxxxxx")
    result="INCONNUE";
  }else if( isOrangePhoneNumber(phoneNumber)){
   // console.log(phoneNumber+"oooooo")
    result= "ORANGE";
  }else if( isMTNPhoneNumber(phoneNumber)){
   // console.log(phoneNumber+"mmmmmm")
    result= "MTN";
  }else if( isNexTelPhoneNumber(phoneNumber)){
   // console.log(phoneNumber+"nnnnntel")
    result= "NEXTTEL";
  }else if(iscamtelPhoneNumber(phoneNumber)){
   // console.log(phoneNumber+"ccccc")
    result="CAMTEL"
  }
  return result;
}
export const regex=/(^(((00)?237)?|\+(237)?)(65[5-9]\d{6}$|69\d{7}$)|(((00)?237)?|\+(237)?)(6(5|8)[0-4]\d{6}$|67\d{7}$)|(((00)?237)?|\+(237)?)(66\d{7}$|68[5-9]\d{6}$)|(((00)?237)?|\+(237)?)(24\d{7}$|620\d{6}$))$/;
export const checklenght=/^(?:[+0]237)?[0-9]{9}$/;
export const spacialCharacter=/^[a-zA-Z0-9\s]*$/;

export function removeSpecialChars(str) {
  return str.replace(/(?!\w|\s)./g, '')
    .replace(/\s+/g, ' ')
    .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
}

export function groupeBy(data,historiques, ifIsAddUserStat){
  const list = [];
  const smsSend= historiques.filter((c)=> c.smscount==="0" && c.type==="SEND_MESSAGE")
  const smsSendAccepted= historiques.filter((c)=> c.smscount>parseInt("0")  && c.type==="SEND_MESSAGE")
  console.log(smsSendAccepted);
  if(ifIsAddUserStat===true){
    console.log(historiques);
     data?.forEach((d)=>{
       const y = new Date(d.data.createdAt).getFullYear();
       // console.log(mth)
       if (!list[y]) {
         list[y] ={
           year: y, 
           data: [
            {
              name: 'Users',
              data: [...groupeBye(data,y)]
            },
            {
              name: 'SMS echoués',
              data: [...groupHistoriques(smsSend,y)]
            },
            {
              name: 'SMS envoyés',
              data: [...groupHistoriques(smsSendAccepted,y)]
            }
          ]
         };
       }
     });
   }else{
    console.log(historiques)
    historiques?.forEach((d)=>{
      const y = new Date(d.createdAt).getFullYear();
      console.log(y);
       // console.log(mth)
       if (!list[y]) {
         list[y] ={
           year: y, 
           data: [
            {
              name: 'SMS echoués',
              data: [...groupHistoriques(smsSend,y)]
            },
            {
              name: 'SMS envoyés',
              data: [...groupHistoriques(smsSendAccepted,y)]
            }
          ] 
         };
       }
     });
   }
   
return list;
}

export function groupeByContactes(contacts){
  const list = [];
  contacts.forEach((d)=>{
   // console.log(d);
    const y = new Date(d.createdAt).getFullYear();
    // console.log(mth)
    if (!list[y]) {
      list[y] ={
        year: y, 
        data: [
          {
            name: 'Orange',
            data: [...groupContacteBy(contacts.filter((contact)=> contact.phone!=null?checkPhoneValidation(contact.phone).includes("ORANGE"): false),y)]
          },
          {
            name: 'MTN',
            data: [...groupContacteBy(contacts.filter((contact)=> contact.phone!=null?checkPhoneValidation(contact.phone).includes("MTN"): false),y)]
          },
          {
            name: 'CAMTEL',
            data: [...groupContacteBy(contacts.filter((contact)=> contact.phone!=null?checkPhoneValidation(contact.phone).includes("CAMTEL"): false),y)]
          },
          {
            name: 'NEXTTEL',
            data: [...groupContacteBy(contacts.filter((contact)=> contact.phone!=null?checkPhoneValidation(contact.phone).includes("NEXTTEL"): false),y)]
          },
          {
            name: 'INCONNUES',
            data: [...groupContacteBy(contacts.filter((contact)=> contact.phone!=null?checkPhoneValidation(contact.phone).includes("INCONNUE"): false),y)]
          },
          {
            name: 'NULL',
            data: [...groupContacteBy(contacts.filter((contact)=> contact.phone===undefined),y)]
          }
        ] 
      };
    }
  });
   
return list;
}
function groupeBye(data,y){
  const monthCountArr = new Array(12).fill(0); 
  // console.log(data)
  for (let i = 0; i <12; i+=1) {
    Array.from(data).forEach((elem)=>{
       console.log(elem.data.createdAt)
     const [year, month]  = [new Date(elem.data.createdAt).getFullYear(), new Date(elem.data.createdAt).getMonth()];
      if(y===year){
        if(i===month){
          monthCountArr[new Date(elem.data.createdAt).getMonth()] += 1
        }
      }
    } 
    );  
  }
  console.log(monthCountArr)
  return monthCountArr;
}
function groupContacteBy(data,y){
  // console.log(y);
  // console.log(data);
  const monthCountArr = new Array(12).fill(0); 
  for (let i = 0; i <12; i+=1) {
    Array.from(data).forEach((elem)=>{
      const [year, month]  = [new Date(elem.createdAt).getFullYear(), new Date(elem.createdAt).getMonth()];
      if(y===year){
        if(i===month){
          monthCountArr[new Date(elem.createdAt).getMonth()] += 1
        }
      }
    } 
    );  
  }
 
  return monthCountArr;
  
}
function groupHistoriques(data,y){
  console.log(data, y)
  const monthCountArr = new Array(12).fill(0); 
  for (let i = 0; i <12; i+=1) {
    Array.from(data).forEach((elem)=>{
      const [year, month]  = [new Date(elem.createdAt).getFullYear(), new Date(elem.createdAt).getMonth()];
      if(y===year){
        if(i===month){
          monthCountArr[new Date(elem.createdAt).getMonth()] += 1
        }
      }
    } 
    );  
  }
   console.log(monthCountArr);
  return monthCountArr;

}
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  const acronym = name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')
  return {
    sx: {
      bgcolor: stringToColor(name),
      color:'white'
    },
    children: `${acronym}`,
  };
}
export const iff = (condition, then, otherwise) => condition ? then : otherwise;
export const paginationNumber=[3,5, 10, 25, 50, 100,250,500,1000,2500];
export const namesToEsclude = ["MOBILEMONEY", "ORANGEMONEY", "AFRILAND", "SGC", "ECOBANK", "UBA", "BGFIBank  ", "EUMOBILE", "BICEC", "BICEC", "Yoomee", "ORANGE", "MTN", "YUP", "UBC", "CBC"]
export  const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/${name}.svg`}  sx={{ width: '100%', height: '100%',  color: '#000000'}} />
);

export const countSpecialChars =(message)=>{
    
  // Je défini la liste des caractères spéciaux
  const specialCharsArray = ['%', '!', '#', '&', '@', '$', '*', '}', '{', '~', '€'];
 
  // Cette liste contiendra les caractères spéciaux qu'on va détecter dans le message
  const detectedSpecial = [];

  // pour chaque caractères dans le message
  [...message].forEach((charItem)=>
      // Pour chaque caractères dans la liste des caractères spéciaux
      specialCharsArray.forEach((specialCharItem)=>{
          // Si le caractères du message est egale à un caractères spécial
          if(charItem === specialCharItem){
              // on l'ajoute dans la liste des caractères spéciaux détectés
              detectedSpecial.push(charItem);
          }
      })
  )

  // taille du message en excluant les caractères spéciaux
  const lengthWithoutSpecial = message.length - detectedSpecial.length;
  // taille du message avec caractères spéciaux (un caractère spécial vaut deux caractères)
  const totalLength =lengthWithoutSpecial + (detectedSpecial.length * 2);

  // on retourne la taille totale du message et un tableau contenant les caractères spéciaux détectés du message
  return {totalLength, detectedSpecial}
}
export const addcode=(number)=>{
  console.log(number);          
  let result;
    if (!number.startsWith('237') && !number.startsWith('00237') && !number.startsWith('+237')){
     result=`+237${number}`;
   }else if(number.startsWith('237')){
     result= `+${number}`;
   }else{
     result=number;
   }
   console.log(result);
   return result;
 }
 /* eslint-disable */

 export const RandomUid=()=>{
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0, 
    v = iff(c === 'x' , r , (r & 0x3 | 0x8));
    return v.toString(16);
});
/* eslint-enable */
 }