import * as Yup from 'yup';
import { useState } from 'react';
import {useSnackbar} from 'notistack5';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  Alert,
  TextField, Box, Button} from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@material-ui/lab';

import useAuth from '../../../hooks/userAuth';
import {useCreateGroupesForOneUser} from '../../../hooks/Api/useGroupes';

const CreateGroupes =({setisLoading,isLoading, handleClose, userData, contactsArray,idOfCurrentUser})=>{
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const  auth= useAuth();
    // const [Crediter, CrediterResut ]= useCrediter();
    console.log(contactsArray);
    console.log(idOfCurrentUser);
    const createGroupesFunction = useCreateGroupesForOneUser(idOfCurrentUser);
    const validationSchema = Yup.object().shape({
        groupName: Yup.string()
        .required('Entrez le nom du groupe ')
    });
    
    const { handleSubmit, reset, control, formState: { errors }} = useForm({
      resolver: yupResolver(validationSchema)
});
    const onSubmitdata =(data) =>{
      console.log(userData)
      console.log(data)
       CreateGroupes(data) 
    };
    
    const CreateGroupes= async(data)=>{
        setisLoading(true);
        console.log(data);
        createGroupesFunction.mutate({'groupName': data.groupName,'listContact': contactsArray},{
            onSuccess: (data) =>{
              enqueueSnackbar('Le Groupe a bien été  creer ', { variant: 'success' });
              handleClose()
            },
            onError: (e) => {
              enqueueSnackbar('opps une erreur est survenu', { variant: 'error' });
              console.log(e);
            },
          });
        setTimeout(() => {
          setisLoading(false);
          handleClose()
        }, 1000);
        
      }

    return (
    <form>
      <Box sx={{ display: "flex", padding: 5, color:'infos'}}>
        {contactsArray.length} contacter(s) sélectioner
      </Box>
      <Controller
        name="groupName"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField autoFocus fullWidth error={Boolean(errors.crediter)}  helperText={errors.crediter}  margin="dense" onChange={onChange} value={value || ''} label="Le nom du groupe" />
        )}
      />
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
      <LoadingButton fullWidth size="md" onClick={handleSubmit(onSubmitdata)}   variant="contained" loading={isLoading}>
          Creer le groupe
        </LoadingButton>
      </Box>
    </form>
    );
}
export default CreateGroupes