import { useState, useEffect} from 'react';
import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import {useSnackbar} from 'notistack5';
import { useDropzone } from 'react-dropzone';
import fileFill from '@iconify/icons-eva/file-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { motion, AnimatePresence } from 'framer-motion';
// material
import fileDownload from 'js-file-download';
import { alpha, styled } from '@material-ui/core/styles';
import {
  Box,
  List,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,Grid
} from '@material-ui/core';
// utils
import { fData } from '../../utils/formatNumber';
//
import { MIconButton } from '../@material-extend';
import { varFadeInRight } from '../animate';
import { UploadIllustration } from '../../assets';
import ModalDialog from '../myComponents/ModalDialog';
import { usePreviewContacts, useSaveContacts, useImporterContacts,useDownloadContatnsFileTemplate} from '../../hooks/Api/useContacts';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object
};

export default function UploadMultiFile({ error, showPreview = false, files, onRemove,handleClose, onRemoveAll, sx, idOfCUrrentUser, ...other }) {
  const [openImport, setOpenImport] = useState(false);
  
  const hasFile = files!==null;
  const [TrackUpload, setTrackUpload] = useState(0);
  const [hasData, sethasData] = useState(null);
  const { enqueueSnackbar} = useSnackbar();
  const previewContacts=usePreviewContacts(idOfCUrrentUser, setTrackUpload);
  const importerContacts=useImporterContacts(idOfCUrrentUser, setTrackUpload);
  const saveContacts=useSaveContacts(idOfCUrrentUser);
  const downloadContacts=useDownloadContatnsFileTemplate();
  
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other
  });
  const handleClickOpenImport = () => {
    setOpenImport(true);
  };
  const handleCloseImport = () => {
    setOpenImport(false);
  };
  useEffect(() => {
    if(saveContacts.isSuccess){
      handleClose()
    }
    return () => {return 0;}
  }, [])
  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
  const downLoadTemplate=()=>{
    downloadContacts.mutate()
    if(downloadContacts.data){
      console.log(downloadContacts.data)
        fileDownload(downloadContacts.data,  `template_excel.xlsx`);
        enqueueSnackbar('fichier télécharger veuillez regarder dans le dossier de téléchargements de votre ordinateur', { variant: 'success' });
     
    }
  }
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Stack spacing={3} direction="row">
       <Grid  container spacing={3}>
        <Grid  item xs={12} md={6} lg={6}>
        <DropZoneStyle
        onClick={()=>{downLoadTemplate()}}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          })
        }}
      >
     

        <UploadIllustration sx={{ width: 220 }} />

        <Box sx={{ p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
          1-  Cliquez sur le cadre pour télécharger le fichier à remplir 
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Técharger le template de fichier en premier
           puis remplissez le avec vos contacts  &nbsp;
          </Typography>
          {downloadContacts.isLoading?
           <Box sx={{display: 'flex',
                    top:'50%',
                    left:'50%',
                    alignItems: 'center',}}>
              <Stack sx={{marginTop:2}} direction="colum">
                <CircularProgress/>
                telechargement en cour  ....
              </Stack>
          </Box> :null}
        </Box>
      </DropZoneStyle>
        </Grid>
        <Grid  item xs={12} md={6} lg={6}>
        <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          })
        }}
      >
        <input {...getInputProps()} />

        <UploadIllustration sx={{ width: 220 }} />

        <Box sx={{ p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
           2- Déposez votre fichier ici 
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Une fois votre fichier remplie , cliquez ici pour sur uploader le fichier  &nbsp;
          en le choisisant dans votre ordinateur puis previsualisez le ou importez le directement 
          </Typography>
        </Box>
      </DropZoneStyle>
        </Grid>
      </Grid>
     
      </Stack>

      {fileRejections.length > 0 && <ShowRejectionItems />}
      {files && (
         
         <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
         <AnimatePresence>
               <ListItem
                 component={motion.div}
                 {...varFadeInRight}
                 sx={{
                   my: 1,
                   py: 0.75,
                   px: 2,
                   borderRadius: 1,
                   border: (theme) => `solid 1px ${theme.palette.divider}`,
                   bgcolor: 'background.paper'
                 }}
               >
                 <ListItemIcon>
                   <Icon icon={fileFill} width={28} height={28} />
                 </ListItemIcon>
                 <ListItemText
                   primary={isString(files) ? files : files.name}
                   secondary={isString(files) ? '' : fData(files.size)}
                   primaryTypographyProps={{ variant: 'subtitle2' }}
                   secondaryTypographyProps={{ variant: 'caption' }}
                 />
                 <ListItemSecondaryAction>
                   <MIconButton edge="end" size="small" onClick={() => onRemove(files)}>
                     <Icon icon={closeFill} />
                   </MIconButton>
                 </ListItemSecondaryAction>
               </ListItem>
         </AnimatePresence>
       </List>
      )}
     

      {hasFile && (
        <Stack direction="row" justifyContent="flex-end">
          {TrackUpload&&<CircularProgress color="info" variant="determinate" value={TrackUpload} sx={{ mr: 1.5 }} />}
          <Button  variant="contained" color="warning" sx={{ mr: 1.5 }} onClick={()=>{
            console.log(files)  
            const form= new FormData();
            form.append('uploaded_file', files);        // console.
            previewContacts.mutate(form,{
              onSuccess: (data) =>{
                sethasData(data.data);
                handleClickOpenImport()
              },
              onError: (e) => {
                console.log(e);
              },
            })
            
          
          }}>Prévisualiser d'abord</Button>
          <ModalDialog
          open={openImport}
          handleClose={handleCloseImport}
          size="lg"
          title="Prévisualisez votre fichier"
          description="Vérifiez que vos données sont  correctes sinon modifiez les en cliquant sur la case correspondantes puis cliquez sur le button importe les données qui est tout en bas"
          typeOfComponents="PreviewFIles"
          isUseComponents={true}
          otherProps={{"idCurrentUser":idOfCUrrentUser, "data":hasData}}
           />
          <Button type="button"
            color="success"
            variant="contained" onClick={()=>{
            console.log(files)  
            const form= new FormData();
            form.append('uploaded_file', files);        
            importerContacts.mutate(form,{
              onSuccess: (data) =>{
                enqueueSnackbar('importation effectué ', { variant: 'success' });
                handleClose();
              },
              onError: (e) => {
                enqueueSnackbar('opps une erreur est survenu', { variant: 'error' });
                console.log(e);
              },
            })
            
          
          }} sx={{ mr: 1.5 }}>
           Importer directement
          </Button>
          <Button type="button"
        color="error"
        variant="contained" onClick={handleClose} sx={{ mr: 1.5 }}>
           fermer
          </Button>
        </Stack>
      )}
    </Box>
  );
}
